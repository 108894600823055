/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import './index.less';
import FilterForm from "../../components/filterForm";

const PageModel = props => {
	const [form] = Form.useForm();
	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);

	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/admin/list',
			data
		});
		if(!result) return;
		const list = result.data.list.map((lst, idx) => {
			lst.key = Math.random() * 100 * (idx + 1);
			return lst;
		});
		setList(list);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const submitHandle = async (data, callback) => {
		let url = '/admin/admin/add';
		if(tempFormValues && tempFormValues.id) {
			data.id = tempFormValues.id;
			url = '/admin/admin/update';
		}
		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/admin/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setTimeout(() => {
			setModalFlag(false);
		}, 20);
	};

	const [ userInfo, setUserInfo ] = useState({});

	return (
		<Page {...{
			syncUserInfo: data => {
				setUserInfo(data);
			},
			title: "管理员",
			breadcrumbs: [{ text: '首页' }, { text: '系统设置' }, { text: '管理员' }],
			...props
		}}>
			<section className="setting-admin-container">
				<div className="list-container">
					<FilterForm {...{
						fields: [
							{ type: 'input', label: '管理员名称', name: 's-name', placeholder: '请输入' },
						],
						filterHandle: data => {
							getList(data);
						},
						extra: {
							btnText: '新增管理员',
							actionHandle: () => {
								form.resetFields();
								setTempFormValues(null);
								setModalFlag(true);
							}
						}
					}} />

					<Table
						{...{
							dataSource: list,
							columns: [
								{ title: '姓名', dataIndex: 'name', key: 'name' },
								{
									title: '管理员类型', dataIndex: 'type', key: 'type',
									render: (text, record, index) => CONST.adminTypes[text] || null
								},
								{ title: '联系方式', dataIndex: 'mobile', key: 'mobile' },
								{ title: '邮箱', dataIndex: 'email', key: 'email' },
								{
									title: '简介', dataIndex: 'intro', key: 'intro',
									render: (text, record, index) => {
										return <div style={{ maxHeight: 88, maxWidth: 320, overflow: 'auto' }}>{text}</div>;
									}
								},
								{
									title: '操作', dataIndex: 'edit', key: 'edit', width: 140, fixed: 'right',
									render: (text, record, index) => {
										const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
										return <>
											<Button size="small" type="primary" style={_style} onClick={() => {
												delete record.password;
												form.resetFields();
												setTempFormValues(record);
												setModalFlag(true);
											}}>编辑</Button>
											{
												Utils.isSuperAdmin(userInfo?.mobile) && <Button danger type="text" size="small" style={_style} onClick={() => {
													Modal.confirm({
														title: '删除的数据将无法找回，请慎重操作！',
														icon: <WarningOutlined style={{ color: '#E45357' }} />,
														// content: 'Bla bla ...',
														okText: '确认删除',
														cancelText: '取消',
														onOk: () => {
															deleteHandle(record.id, () => {
																getList();
															})
														},
														onCancel: () => {
															// console.log('Cancel');
														},
													});
												}}>删除</Button>
											}
										</>
									}
								},
							]
						}} />
				</div>
			</section>

			{
				!!modalFlag && <Modal {...{
					open: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="姓名" name="name">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						{
							(!tempFormValues || !tempFormValues.id) && <Form.Item label="密码" name="password">
								<Input placeholder="请输入" type="password" allowClear />
							</Form.Item>
						}
						<Form.Item label="管理员类型" name="type">
							<Select placeholder="超级管理员/普通管理员" allowClear>
								{
									Object.keys(CONST.adminTypes).map((key, idx) => {
										if(key != 1) {
											return (
												<Select.Option value={Number(key)} key={idx}>{CONST.adminTypes[key]}</Select.Option>
											)
										}
									})
								}
							</Select>
						</Form.Item>
						<Form.Item label="联系方式" name="mobile">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="邮箱" name="email">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="简介" name="intro">
							<Input.TextArea placeholder="请输入" allowClear />
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
