/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import './index.less';
import FilterForm from "../../components/filterForm";

const PageModel = props => {
	const [form] = Form.useForm();
	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);

	const [ list, setList ] = useState([]);
	const [perlist, setPerList] = useState([]);

	const getUsers = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/cunju/account',
			data
		});
		if (!result) return;
		console.log('result',result);
	};
	const getAccount = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/admin/account',
			data
		});
		if (!result) return;
		console.log('result', result);
	};

	const getList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/role/list',
			data
		});
		if(!result) return;
		const list = result.data.list.map((lst, idx) => {
			lst.key = Math.random() * 100 * (idx + 1);
			return lst;
		});
		setList(list);
	};
	const getPerList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/permission/list',
			data
		});
		if (!result) return;
		setPerList(result.data.list);
	};
	useEffect(() => {
		getList();
		//获取权限列表
		getPerList();
		//测试接口 刷数据
		
		//创建村居账号  从user表查询 插入admin表  有就修改，没有就新增 type改为2
		// getUsers();
		//城运中心管理员账号权限升级 从admin表查询指定的账号 有-tye改为4 没有  新增 type为4
		// getAccount();
	}, [123]);

	const submitHandle = async (data, callback) => {
		let url = '/admin/role/add';

		if (tempFormValues && tempFormValues.id) {
			data.id = tempFormValues.id;
			url = '/admin/role/update';
		}

		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/role/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setTimeout(() => {
			setModalFlag(false);
		}, 20);
	};

	const [ userInfo, setUserInfo ] = useState({});

	return (
		<Page {...{
			syncUserInfo: data => {
				setUserInfo(data);
			},
			title: "角色管理",
			breadcrumbs: [{ text: '首页' }, { text: '系统设置' }, { text: '角色管理' }],
			...props
		}}>
			<section className="setting-admin-container">
				<div className="list-container">
					<FilterForm {...{
						fields: [
							{ type: 'input', label: '角色管理', name: 's-name', placeholder: '请输入' },
						],
						filterHandle: data => {
							getList(data);
						},
						extra: {
							btnText: '新增角色',
							actionHandle: () => {
								form.resetFields();
								setTempFormValues(null);
								setModalFlag(true);
							}
						}
					}} />

					<Table
						{...{
							dataSource: list,
							columns: [
								{ title: '角色', dataIndex: 'type', key: 'type',
									render: (text, record, index) => CONST.adminTypes[text] || null },
								{ title: '所属权限', dataIndex: 'pname', key: 'pname' },
								{
									title: '操作', dataIndex: 'edit', key: 'edit', width: 140, fixed: 'right',
									render: (text, record, index) => {
										const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
										return <>
											<Button size="small" type="primary" style={_style} onClick={() => {
												delete record.password;
												form.resetFields();
												setTempFormValues(record);
												setModalFlag(true);
											}}>编辑</Button>
											{/* {
												Utils.isSuperAdmin(userInfo?.mobile) && <Button danger type="text" size="small" style={_style} onClick={() => {
													Modal.confirm({
														title: '删除的数据将无法找回，请慎重操作！',
														icon: <WarningOutlined style={{ color: '#E45357' }} />,
														// content: 'Bla bla ...',
														okText: '确认删除',
														cancelText: '取消',
														onOk: () => {
															deleteHandle(record.id, () => {
																getList();
															})
														},
														onCancel: () => {
															// console.log('Cancel');
														},
													});
												}}>删除</Button>
											} */}
										</>
									}
								},
							]
						}} />
				</div>
			</section>

			{
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="角色" name="type">
							<Select placeholder="请选择" allowClear>
								{
									Object.keys(CONST.adminTypes).map((key, idx) => {
										if (key != 1) {
											return (
												<Select.Option value={Number(key)} key={idx}>{CONST.adminTypes[key]}</Select.Option>
											)
										}
									})
								}
							</Select>
						</Form.Item>
						<Form.Item label="所属权限" name="permissionId">
							<Select placeholder="请选择" allowClear>
								{
									perlist.length > 0 && perlist.map((key, idx) => {
											return (
												<Select.Option value={key.id} key={key.id}>{key.name}</Select.Option>
											)
									})
								}
							</Select>
						</Form.Item>
						<Form.Item label="描述信息" name="description">
							<Input placeholder="请输入" allowClear />
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
