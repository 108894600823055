/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/admin/dynamic/list'
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList([...result.data.list]);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);
	const [ fileList, setFileList ] = useState([]);
	const [ editorState, setEditorState ] = useState(undefined);
	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setModalFlag(false);
	};
	const submitHandle = async (data, callback) => {
		let url = '/admin/dynamic/add';
		if(tempFormValues && tempFormValues.id) {
			data.id = tempFormValues.id;
			url = '/admin/dynamic/update';
		}
		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);
		setFileList([]);
		setEditorState(undefined);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/dynamic/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	const editorUploadHandle = (param) => {
		const serverURL = CONST.uploadPrefix + '/admin/dynamic/upload';
		const xhr = new XMLHttpRequest;
		const fd = new FormData();
		const successFn = (event) => {
			let { response } = event.target;
			response = JSON.parse(response);
			response.data.src = response.data.src.startsWith('http') ? response.data.src : CONST.imgPrefix + response.data.src;
			// 假设服务端直接返回文件上传后的地址
			// 上传成功后调用param.success并传入上传后的文件地址
			param.success({
				url: response.data.src,
				meta: {
					id: param.id,
					title: 'xxx',
					alt: 'xxx',
					loop: true, // 指定音视频是否循环播放
					autoPlay: true, // 指定音视频是否自动播放
					controls: true, // 指定音视频是否显示控制栏
					poster: response.data.src, // 指定视频播放器的封面
				}
			})
		};
		const progressFn = (event) => {
			// 上传进度发生变化时调用param.progress
			param.progress(event.loaded / event.total * 100)
		};
		const errorFn = (response) => {
			// 上传发生错误时调用param.error
			param.error({
				msg: 'unable to upload.'
			})
		};
		xhr.upload.addEventListener("progress", progressFn, false);
		xhr.addEventListener("load", successFn, false);
		xhr.addEventListener("error", errorFn, false);
		xhr.addEventListener("abort", errorFn, false);

		fd.append('file', param.file);
		xhr.open('POST', serverURL, true);
		xhr.send(fd);
	};

	return (
		<Page {...{
			title: '动态管理',
			breadcrumbs: [ { text: '首页' }, { text: '动态管理' } ],
			...props
		}}>
			<section className="dynamic-container">
				<Button type="primary" style={{ marginBottom: 20 }} onClick={() => {
					form.resetFields();
					setTempFormValues(null);
					setModalFlag(true);
				}}>新增动态</Button>
				<Table {...{
					columns: [
						Utils.beautyTableColunm({
							title: '标题', dataIndex: 'title', key: 'title', width: 250
						}),
						{
							title: '封面图', dataIndex: 'cover', key: 'cover',
							render: (text, record, index) => {
								if(record.cover && record.cover.length > 0) {
									return <img src={CONST.imgPrefix + record.cover[0]} style={{ height: 56 }} />
								}
								return null;
							}
						},
						{ title: '作者', dataIndex: 'author', key: 'author' },
						{
							title: '发布时间', dataIndex: 'createdAt', key: 'createdAt',
							render: (text, record, index) => <span>{record.createdAt && moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
						},
						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 180, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
								return <>
									<Button size="small" type="primary" style={_style} onClick={() => {
										form.resetFields();
										setTempFormValues(record);
										setEditorState(record.content ? BraftEditor.createEditorState(record.content) : undefined);
										setModalFlag(true);
									}}>编辑</Button>
									<Button danger type="text" size="small" style={_style} onClick={() => {
										Modal.confirm({
											title: '删除的数据将无法找回，请慎重操作！',
											icon: <WarningOutlined style={{ color: '#E45357' }} />,
											// content: 'Bla bla ...',
											okText: '确认删除',
											cancelText: '取消',
											onOk: () => {
												deleteHandle(record.id, () => {
													getList();
												})
											},
											onCancel: () => {
												// console.log('Cancel');
											},
										});
									}}>删除</Button>
								</>
							}
						},
					],
					dataSource: list
				}} />
			</section>

			{
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							if(editorState && editorState.toHTML() != '<p></p>') {
								values.content = editorState.toHTML();
							}

							values.cover = fileList && fileList.length > 0 && fileList || (tempFormValues && tempFormValues.cover) || [];
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="封面" name="cover">
							<UploadPictureList {...{
								pictureLength: 1,
								action: CONST.uploadPrefix + '/admin/dynamic/upload',
								fileList: tempFormValues && tempFormValues.cover || [],
								changeHandle: fileList => {
									setFileList(fileList);
								}
							}} />
						</Form.Item>
						<Form.Item label="标题" name="title">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="作者" name="author">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="原文地址" name="originLink">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="动态内容">
							{/*<Input placeholder="请输入" allowClear />*/}
							<BraftEditor
								value={editorState}
								onChange={setEditorState}
								media={{
									uploadFn: editorUploadHandle
								}}
							/>
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;