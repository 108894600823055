/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Radio, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [list, setList] = useState([]);
	const getList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/send/list',
			data
		});
		if (!result) return;

		setList([...result.data.list]);
	};
	useEffect(() => {
		getList();
	}, [123]);

	return (
		<Page {...{
			title: '商品管理',
			breadcrumbs: [{ text: '首页' }, { text: '商品管理' }],
			...props
		}}>
			<section className="product-container">
				<FilterForm {...{
					fields: [
						{ type: 'select', label: '反馈类型', name: 's-type', placeholder: '请选择', options: [{ id: 1, label: '交通管理' }, { id: 2, label: '环境保护' }, { id: 3, label: '公共事业' }, { id: 4, label: '其他诉求' }]},
						{ type: 'input', label: '村居', name: 's-telname', placeholder: '请输入' },
						{ type: 'range-date', label: '拨打时间', name: 's-createdAt', placeholder: ['请选择', '请选择'], style: { width: 360 } },
					],
					filterHandle: data => {
						getList(data);
					},
					exportHandle: async data => {
						// data.status = Utils.getSearchByKey('status');
						const result = await Request.get({
							url: '/admin/send/list/export',
							data
						});
						if (!result) return;
						window.open(result.data.path);
					},
					// extra: {
					// 	btnText: '新增商品',
					// 	actionHandle: () => {
					// 		form.resetFields();
					// 		setTempFormValues(null);
					// 		setModalFlag(true);
					// 	}
					// }
				}} />
				<Table {...{
					columns: [
						{ title: '反馈类型', dataIndex: 'typename', key: 'typename' },
						{ title: '村居', dataIndex: 'telname', key: 'telname'},
						{ title: '电话号码', dataIndex: 'telcode', key: 'telcode' },
						{
							title: '接打时间', dataIndex: 'createdAt', key: 'createdAt',
							render: (text, record, index) => <span>{record.createdAt && moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
						},
					],
					dataSource: list
				}} />
			</section>

			{/* {
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							values.cover = fileList && fileList.length > 0 && fileList || (tempFormValues && tempFormValues.cover) || [];
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="封面" name="cover">
							<UploadPictureList {...{
								pictureLength: 1,
								action: CONST.uploadPrefix + '/admin/product/upload',
								fileList: tempFormValues && tempFormValues.cover || [],
								changeHandle: fileList => {
									setFileList(fileList);
								}
							}} />
						</Form.Item>
						<Form.Item label="商品名称" name="name">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="所需兑换的积分值" name="price">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="库存" name="store">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="兑换地址" name="address">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="上下架" name="status">
							<Radio.Group>
								<Radio value={1}>上架</Radio>
								<Radio value={2}>下架</Radio>
							</Radio.Group>
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			} */}
		</Page>
	);
};

export default PageModel;
