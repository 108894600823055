/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/order/write-off/list'
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList([...result.data.list]);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [ data, setData ] = useState({});
	const getData = async () => {
		const { number } = props.match.params;
		if(!number) return;

		const result = await Request.get({
			url: '/admin/order/write-off/get',
			data: { number }
		});
		if(!result) return;
		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ props.match.params ]);

	const submitHandle = async () => {
		Modal.confirm({
			title: '确认核销该订单！',
			icon: <WarningOutlined style={{ color: '#E45357' }} />,
			// content: 'Bla bla ...',
			okText: '是的，确认',
			cancelText: '取消',
			onOk: async () => {
				const result = await Request.post({
					url: '/admin/order/write-off',
					data: {
						id: data.id
					}
				});
				if(!result) return;
				message.success('操作成功，可在核销列表中查看记录');

				getData();
			},
			onCancel: () => {
				// console.log('Cancel');
			},
		});
	};

	const [ flag, setFlag ] = useState(false);

	return (
		<section className="writeoff-container">
			<div className="title-info">订单基本信息</div>
			<div className="baseinfo">
				{
					[
						{ label: '订单编号', text: data.number },
						{ label: '购买时间', text: data.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss') },
						{ label: '兑换商品', text: data.productName },
						{ label: '订单状态', text: ({ 1: '待领取', 2: '已领取' })[data.status] },
						{ label: '核销人员', text: data?.writeOffUserInfo?.name || '-' },
						{ label: '领取时间', text: data.collectTime },
					].map((item, idx) => {
						return(
							<div className="item" key={idx}><label>{item.label}：</label><span>{item.text}</span></div>
						)
					})
				}
			</div>
			{
				data?.status == 1 && <div className="action-button" onClick={submitHandle}>立即核销</div>
			}
			<div className="action-button action-button2" onClick={async () => {
				await getList();
				setFlag(true);
			}}>查看核销记录</div>

			{
				flag && <>
					<section className="writeoff-list-bg" onClick={() => setFlag(false)} />
					<section className="writeoff-list">
						{
							list.map((item, idx) => {
								return(
									<section className="writeoff-item" key={idx}>
										<div className="writeoff-item-inner"><label>核销/领取时间：</label><span>{item.collectTime}</span></div>
										<div className="writeoff-item-inner"><label>订单编号：</label><span>{item.number}</span></div>
										<div className="writeoff-item-inner"><label>兑换商品：</label><span>{item.productName}</span></div>
										<div className="writeoff-item-inner"><label>核销操作员：</label><span>{item.writeOffUserInfo.name}</span></div>
									</section>
								)
							})
						}
					</section>
				</>
			}
		</section>
	);
};

export default PageModel;
