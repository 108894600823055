/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Card, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload, Statistic } from 'antd';
import { Line, Pie, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, CaretUpOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import LessonList from '../../components/lessonList';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [caseCount, setCaseCount] = useState({});
	const [ departmentCaseList, setDepartmentCaseList ] = useState([]);
	const [ departmentList, setDepartmentList] = useState([]);
	const [categoryCaseList, setCategoryCaseList] = useState([]);
	const [goryCaseList, setgoryCaseList] = useState([]);

	const getCount = async (url, callback) => {
		const result = await Request.get({
			url
		});
		if (!result) return;

		callback && typeof callback == 'function' && callback(result.data);
	};

	useEffect(() => {
		getCount('/admin/stat/casehot', setCaseCount);
		getDepartmentCaseList();
		getDepartmentList();
		getCategoryCaseList();
		getgoryCaseList();
	}, [123]);

	const getDepartmentCaseList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/department-case/list2',//获取村居案件分部图
			data
		});
		if(!result) return;
		setDepartmentCaseList(result.data.asy);
	};
	const getDepartmentList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/department-case/list3',//获取村居案件分部图
			data
		});
		if (!result) return;
		let array = result.data.list;
		let asy = [];
		for (let index = 0; index < array.length; index++) {
			const element = array[index];
			asy.push({ "type": element.telname, "value": element.count })
		}
		console.log('list==', asy);
		setDepartmentList(asy);
	};
	const getCategoryCaseList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/category-case/list2',//获取案件类型分布图
			data
		});
		if (!result) return;

		const map = {};
		result.data.list.map(item => {
			if (item.categoryLabel) {
				map[item.categoryLabel] = map[item.categoryLabel] || 0;
				map[item.categoryLabel] += Number(item.count);
			}
			// item.type = item.categoryLabel;
			// item.value = item.count;
			// return item;
		});
		const list = [];
		Object.keys(map).map(type => {
			list.push({
				type,
				value: map[type]
			});
		});
		setCategoryCaseList(list);
		console.log('list', list);
	};
	const getgoryCaseList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/category-case/list3',//获取案件类型分布图
			data
		});
		if (!result) return;

		const map = {};
		result.data.list.map(item => {
			if (item.typename) {
				map[item.typename] = map[item.typename] || 0;
				map[item.typename] += Number(item.count);
			}
			// item.type = item.categoryLabel;
			// item.value = item.count;
			// return item;
		});
		const list = [];
		Object.keys(map).map(type => {
			list.push({
				type,
				value: map[type]
			});
		});
		console.log('2===',list);
		setgoryCaseList(list);
	};

	const selDate = (date, dateString) =>{
		console.log(dateString);
		getDepartmentCaseList({ "time": `${dateString[0]}` + ',' + `${dateString[1]}` })
	}

	return (
		<Page {...{
			title: '12345热线事件',
			breadcrumbs: [{ text: '首页' }, { text: '12345热线事件' } ],
			...props
		}}>
			<section className="dashboard-container">
				<div className="column-left">
					<div style={{ display: 'flex' }}>
						<div className="chart-block" style={{ height: 308 }}>
							<div className="chart-title">
								<span>村居12345热线案件分布图</span>
								<div className="time-range">
									{/* <span>本周</span> */}
									{/* <span>本月</span> */}
									{/* <span>本年</span> */}
									<DatePicker.RangePicker placeholder={['起始时间', '结束时间']} style={{ marginLeft: 10 }} onChange={selDate} />
								</div>
							</div>
							<Column {...{
								data: departmentCaseList,
								xField: 'label',
								yField: 'value',
								stack: true,
								colorField: 'type',
								label: {
									text: 'value',
									textBaseline: 'bottom',
									position: 'inside',
								},
								interaction: {
									elementHighlightByColor: {
										link: true,
									},
								},
								state: {
									active: { linkFill: 'rgba(0,0,0,0.25)', stroke: 'black', lineWidth: 0.5 },
									inactive: { opacity: 0.5 },
								},
							}} />
						</div>
					</div>
					{/* <div style={{ display: 'flex' }}>
						<div className="chart-block" style={{ height: 308 }}>
							<div className="chart-title">
								<span>村居12345电话拨打数量</span>
								<div className="time-range">
									<span>本周</span>
									<span>本月</span>
									<span>本年</span>
									<DatePicker.RangePicker placeholder={['起始时间', '结束时间']} style={{ marginLeft: 10 }} />
								</div>
							</div>
							<Column {...{
								data: departmentList,
								xField: 'label',
								yField: 'value',
								stack: true,
								colorField: 'type',
								label: {
									text: 'value',
									textBaseline: 'bottom',
									position: 'inside',
								},
								interaction: {
									elementHighlightByColor: {
										link: true,
									},
								},
								state: {
									active: { linkFill: 'rgba(0,0,0,0.25)', stroke: 'black', lineWidth: 0.5 },
									inactive: { opacity: 0.5 },
								},
							}} />
						</div>
					</div> */}
					<div style={{ display: 'flex' }}>
						<div className="chart-block">
							<div className="chart-title">12345图文上报分布图</div>
							<Pie {...{
								appendPadding: 10,
								data: categoryCaseList,
								angleField: 'value',
								colorField: 'type',
								radius: 1,
								innerRadius: 0.5,
								label: {
									type: 'outer',
									content: '{name} {value}',
								},
								interactions: [
									{
										type: 'element-selected',
									},
									{
										value: 'element-active',
									},
								],
								statistic: null,
							}} />
						</div>
						<div className="chart-block">
							<div className="chart-title">12345电话上报类型分布图</div>
							<Pie {...{
								appendPadding: 10,
								data: goryCaseList,
								angleField: 'value',
								colorField: 'type',
								radius: 1,
								innerRadius: 0.5,
								label: {
									type: 'outer',
									content: '{name} {value}',
								},
								interactions: [
									{
										type: 'element-selected',
									},
									{
										value: 'element-active',
									},
								],
								statistic: null,
							}} />
						</div>
						<div className="chart-block">
							<div className="chart-title">12345电话拨打次数</div>
							<Pie {...{
								appendPadding: 10,
								data: departmentList,
								angleField: 'value',
								colorField: 'type',
								radius: 1,
								innerRadius: 0.5,
								label: {
									type: 'outer',
									content: '{name} {value}',
								},
								interactions: [
									{
										type: 'element-selected',
									},
									{
										value: 'element-active',
									},
								],
								statistic: null,
							}} />
						</div>
						<div className="column-right">
							<div className="important-datas">
								<div className="item"><label>立案数</label><span>{caseCount.lianCount || '暂无'}</span></div>
								<div className="item"><label>结案数</label><span>{caseCount.jieanCount || '暂无'}</span></div>
								<div className="item"><label>结案率</label><span><CaretUpOutlined style={{ marginRight: 4, color: 'red', display: 'none' }} />{caseCount.radio || '暂无'}</span></div>
								<div className="item"><label>市民满意度</label><span>{caseCount.manyiRadio || '暂无'}</span></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
