/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect, useContext, useRef} from 'react';
import {
	Button,
	Form,
	Select,
	Table,
	Modal,
	Input,
	DatePicker,
	InputNumber,
	message,
	Radio,
	Row,
	Col,
	Divider,
	Typography
} from 'antd';
import {Line, OrganizationTreeGraph, Column, measureTextWidth} from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import ProblemBtnItems from '../../components/problemBtnItems';

import './index.less';

const PageModel = props => {
	const [ modalFlag, setModalFlag ] = useState(false);
	const [ modalConfig, setModalConfig ] = useState({});
	const [ temp, setTemp ] = useState({});
	const [ processor, setProcessor ] = useState('');
	const [ result, setResult ] = useState('');
	const [ categoryList, setCategoryList ] = useState([]);
	const getCategoryLabel = (list = []) => {
		let label = [];
		list.map((code, idx) => {
			const [ target ] = categoryList.filter(item => item.code == code);
			if(target && target.label) {
				if(idx == 0) {
					label.push(`<span style="font-weight: bold; font-size: 14px;">${target.label}</span>`);
				}else{
					label.push(target.label);
				}
			}
		})
		label = label.join('<br />');
		return label;
	};

	const getCategoryType = (pcode, ccode) => {
		let str = '';
		if((pcode && pcode.startsWith('B')) || ccode && ccode.startsWith('B')) {
			str = '[部件]';
		}
		if((pcode && pcode.startsWith('S')) || ccode && ccode.startsWith('S')) {
			str = '[事件]';
		}
		return str;
	}
	const getCategoryList = async () => {
		const result = await Request.get({
			url: '/admin/problem/category/list'
		});
		if(!result) return;

		if(result.data.list && result.data.list.length > 0) {
			setCategoryList(result.data.list);
		}
	};
	const [ listQuery, setListQuery ] = useState({});
	const [ list, setList ] = useState([]);
	const [ page, setPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState(10);
	const [ pageTotal, setPageTotal ] = useState(0);
	const [ preQuery, setPreQuery ] = useState({});
	const getList = async (data = {}) => {
		await getWorkerList();
		const status = Utils.getSearchByKey('status');
		data.page = page - 1;
		data.pageSize = pageSize;
		data.status = status;
		data.id = window.localStorage.getItem("id");

		const result = await Request.get({
			url: '/admin/problem/list',
			data: {
				...listQuery,
				...data
			}
		});
		if(!result) return;

		if(result.data.list && result.data.list.length > 0) {
			result.data.list.map((item, idx) => {
				item.key = String(Math.random()) + idx;

				return item;
			});
		}
		setList(result.data.list);
		setPageTotal(result.data.count);

		setPreQuery({
			...data,
			status
		});
	};
	const getWearth = async () => {
		let data = {
			location: '121.225084,31.246324'
		}
		const result = await Request.get({
			url: '/admin/common/weather/now',
			data:data
		});
		if (!result) return;
		console.log('当前的天气:',result);
		
	};
	useEffect(() => {
		getSevenCategoryList();
		getCategoryList();
		getList();

		//测试天气
		// getWearth();
	}, [ Utils.getSearchByKey('status'), page, listQuery ]);
	useEffect(() => {
		setPage(1);
	}, [ pageSize ]);

	const [ workerList, setWorkerList ] = useState([]);
	const getWorkerList = async () => {
		const result = await Request.get({
			url: '/admin/user/worker/list'
		});
		if(!result) return;

		if(result.data.list && result.data.list.length > 0) {
			setWorkerList(result.data.list);
		}
	};
	useEffect(() => {
	}, [ 123 ]);

	const doProcess = async (data, callback) => {
		const result = await Request.post({
			url: '/admin/problem/process/do',
			data
		});
		if(!result) return;

		if(callback && typeof callback == 'function') {
			callback();
		}
		getList();
	};
	const cancelModal = () => {
		setModalConfig({});
		setModalFlag(false);
	};
	const selectCategoryList = (categoryList => {
		let map = null;
		if(categoryList && categoryList.length > 0) {
			const _map = {};
			const _pmap = {};
			categoryList.map(cate => {
				_pmap[cate.code] = cate.label;
				if(cate.parentId != '0') {
					_map[cate.parentId] = _map[cate.parentId] || [];
					cate.id = `${cate.parentId},${cate.code}`;
					_map[cate.parentId].push(cate);
				}
			});
			map = {};
			Object.keys(_map).map(k => {
				map[_pmap[k]] = _map[k];
			});
		}
		return map;
	})(categoryList);

	const [ sevenCategory, setSevenCategoryList ] = useState([]);
	const getSevenCategoryList = async () => {
		// const result = await Request.get({
		// 	url: '/admin/problem/category/list',
		// 	data: { type: 'seven' }
		// });
		// if(!result) return;

		if(CONST.sevenCategoryList && CONST.sevenCategoryList.length > 0) {
			setSevenCategoryList(CONST.sevenCategoryList);
		}
	};
	useEffect(() => {
	}, [ 123 ]);

	// const onFocusHandle = () => {
	// 	console.log("Tab is in focus");
	// 	getList(preQuery);
	// };
	// useEffect(() => {
	// 	document.addEventListener('visibilitychange', onFocusHandle);
	// 	window.addEventListener("focus", onFocusHandle);
	// 	return () => {
	// 		document.removeEventListener('visibilitychange', onFocusHandle);
	// 		window.removeEventListener("focus", onFocusHandle);
	// 	};
	// }, [ preQuery ]);

	return (
		<Page {...{
			title: '问题管理',
			breadcrumbs: [ { text: '首页' }, { text: '案件管理' } ],
			...props
		}}>
			<section className="problem-container">
				{
					selectCategoryList && <FilterForm {...{
						fields: [
							{ type: 'input', label: '问题编号', name: 's-number', placeholder: '请输入' },
							{ type: 'select', label: '分类', name: 's-type', placeholder: '请选择', options: CONST.getProblemTypeList().map(item => ({ id: item.status, label: item.statusLabel })) },
							{ type: 'select', label: '处置部门', name: 's-department', placeholder: '请选择', options: CONST.departmentMap.map(item => ({ id: item, label: item })) },
							{ type: 'select', label: '案件类型', name: 's-category', placeholder: '请选择', optionGroups: selectCategoryList },
							{ type: 'range-date', label: '上报时间', name: 's-time', placeholder: ['请选择', '请选择'], style: { width: 360 } },
						],
						filterHandle: data => {
							// console.log('filterHandle, data', data);
							if(data.time){
								const start = data.time[0] && moment(data.time[0]).format('YYYY-MM-DD');
								const end = data.time[1] && moment(data.time[1]).format('YYYY-MM-DD');
								data.reportTime = `${start},${end}`;
								delete data.time;
							}
							setListQuery({...data});
							setPage(1);
							// getList(data);
						},
						exportHandle: async data => {
							data.status = Utils.getSearchByKey('status');
							const result = await Request.get({
								url: '/admin/problem/list/export',
								data
							});
							if(!result) return;
							window.open(result.data.path);
						},
					}} />
				}

				<Table {...{
					style: { width: window.innerWidth - 168 - 20*2 },
					scroll: { x: 7 * 156 + 220 },
					columns: [
						{
							title: '问题编号', dataIndex: 'number', key: 'number', width: 156, fixed: 'left',
							render: (text, record, index) => <span>{text.substr(0, 16)}</span>
						},
						{
							title: '分类', dataIndex: 'type', key: 'type', width: 156, fixed: 'left',
							render: (text, record, index) => <span>{record.type == 'hotevent' ? '12345市民呼声':'社情民意'}</span>
						},
						{
							title: '上报用户', dataIndex: 'reporter', key: 'reporter', width: 156,
							render: (text, record, index) => {
								let str = record.reporterInfo && record.reporterInfo.nickname;
								if(record.reporterInfo && record.reporterInfo.department) {
									str += `（${record.reporterInfo.department}）`;
								}
								return <span>{str}</span>
							}
						},
						{
							title: '问题描述', dataIndex: 'content', key: 'content', width: 200,
							render: (text, record, index) => {
								return <>
									{ record.content && <span>{record.content}</span> }
									{ record.voiceContent && <audio src={CONST.imgPrefix + record.voiceContent} controls={true} style={{ width: 160, height: 32 }} /> }
								</>
							}
						},
						// {
						// 	title: '上传照片', dataIndex: 'covers', width: 88, key: 'covers',
						// 	render: (text, record, index) => {
						// 		if(record.covers.length > 0) {
						// 			return <div style={{ display: 'flex' }}>
						// 				{
						// 					record.covers.map((cover, idx) => cover && <img key={idx} src={CONST.imgPrefix + cover} style={{ width: 32, height: 32, marginRight: 4 }} /> || null)
						// 				}
						// 			</div>
						// 		}
						// 		return null;
						// 	}
						// },
						{
							title: '发生地址', dataIndex: 'address', key: 'address',
							render: (text, record, index) => <div>{record.address}<Typography.Link target="_blank" href={`https://ditu.amap.com/search?query=${encodeURIComponent(record.address)}`} style={{ paddingLeft: 8 }}>查看</Typography.Link></div>
						},
						{
							title: '问题类型', dataIndex: 'category', key: 'category',
							render: (text, record, index) => {
								console.log('record=',record);
								if(record.category && record.category.length > 0) {
									return <div style={{ display: 'flex', flexDirection: 'column' }}>
										<span>[事件]</span>
										<span dangerouslySetInnerHTML={{ __html: getCategoryLabel(record.category) }} />
									</div>
								}else if(record.categoryBu && record.categoryBu.length > 0) {
									return <div style={{ display: 'flex', flexDirection: 'column' }}>
										<span>[部件]</span>
										<span dangerouslySetInnerHTML={{ __html: getCategoryLabel(record.categoryBu) }} />
									</div>
								}
								return null;
								// return <div style={{ display: 'flex', flexDirection: 'column' }}>
								// 	<span>{record.category ? '[事件]' : '[部件]'}</span>
								// 	<span dangerouslySetInnerHTML={{ __html: record.category && getCategoryLabel(record.category[0], record.category[1]) || getCategoryLabel(record.categoryBu[0], record.categoryBu[1], record.categoryBu[2]) }} />
								// </div>
							}
						},
						{
							title: '属于"七大类"', dataIndex: 'sevenCode', key: 'sevenCode',
							render: (text, record, index) => {
								const [ target = {} ] = sevenCategory.filter(item => item.code == record.sevenCode);
								return <span>{target && target.label || '否'}</span>
							}
						},
						{
							title: '处理进度', dataIndex: 'status', key: 'status', width: 88,
							render: (text, record, index) => <span>{CONST.problemStatusMap[record.status]}</span>
						},
						{
							title: '处理部门', dataIndex: 'processorInfo.department', key: 'processorInfo.department', width: 88,
							render: (text, record, index) => <span>{record.processorInfo && record.processorInfo.department || ''}</span>
						},
						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 220, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10, fontSize: 12 };
								return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
									<Button size="small" style={_style} key="detail" onClick={() => {
										window.location.href = `/problem/${record.id}`;
										// window.open(`/problem/${record.id}`, "_blank");
										// window.focus();
									}}>查看详情</Button>
									<ProblemBtnItems {...{
										status: record.status,
										type: record.type,
										record,
										workerList: workerList,
										categoryList:categoryList,
										sevenCategory:sevenCategory,
										callback: () => {
											getList();
										}
									}} />
								</div>
							}
						},
					],
					dataSource: list,
					pagination: {
						current: page,
						onChange: setPage,
						total: pageTotal,
						onShowSizeChange: (current, size) => {
							setPageSize(size);
							setPage(1);
						},
						showTotal: total => `共${total}条数据`
					}
				}} />
			</section>

			<Modal {...{
				visible: modalFlag,
				title: <><WarningOutlined /><span>提示</span></>,
				footer: null
			}}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{modalConfig.title}</span>
					{
						modalConfig.body && <div style={{ marginTop: 10 }}>{modalConfig.body}</div> || null
					}
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
					<Button onClick={cancelModal}>取消</Button>
					<Button type="primary" style={{ marginLeft: 16 }} onClick={() => {
						modalConfig.action({ processor, result });
					}}>确认</Button>
				</div>
			</Modal>
		</Page>
	);
};

export default PageModel;
