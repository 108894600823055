/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Select, TreeSelect, Tree,Table, Modal, Input, DatePicker, InputNumber, message, Row, Col } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import './index.less';
import FilterForm from "../../components/filterForm";

const treeDatas= [
	{
		title: '数据分析',
		key: 'dashboard',
		children: [
			{
				title: '网格化事件',
				key: '/dashboard',
			},
			{
				title: '一呼即应',
				key: '/dashboardhot',
			}, 
			{
				title: '电话接打记录',
				key: '/dashboardlist',
			},
		],
	},
	{
		title: '案件派遣',
		key: 'problem3',
		children: [
			{
				title: '待派遣',
				key: '/problem?status=31',
			},
			{
				title: '再派遣',
				key: '/problem?status=32',
			},
			{
				title: '管控',
				key: '/problem?status=33',
			},
			{
				title: '归档',
				key: '/problem?status=12',
			},
		],
	},
	{
		title: '案件结案',
		key: 'problem4',
		children: [
			{
				title: '事件处理',
				key: '/problem?status=40',
			},
			{
				title: '待结案',
				key: '/problem?status=41',
			},
			{
				title: '已结案',
				key: '/problem?status=42',
			},
		],
	},
	{
		title: '案件督办',
		key: 'problem5',
		children: [
			{
				title: '退单',
				key: '/problem?status=51',
			},
			{
				title: '延期',
				key: '/problem?status=52',
			},
			{
				title: '申诉',
				key: '/problem?status=53',
			},
		],
	},
	{
		title: '市民留言',
		key: 'message',
		children: [
			{
				title: '留言信息',
				key: '/message',
			},
		],
	},
	{
		title: '积分商城',
		key: 'product',
		children: [
			{
				title: '积分商城',
				key: '/product',
			},
		],
	},
	{
		title: '积分明细',
		key: 'score',
		children: [
			{
				title: '市民积分',
				key: '/score',
			},
			{
				title: '村居积分',
				key: '/dscore',
			},
		],
	},
	{
		title: '兑换订单',
		key: 'order',
		children: [
			{
				title: '兑换订单',
				key: '/order',
			},
		],
	},
	{
		title: '用户信息',
		key: 'user',
		children: [
			{
				title: '处置人员',
				key: '/worker',
			},
			{
				title: '普通用户',
				key: '/user',
			},
		],
	},
	{
		title: '系统管理',
		key: 'system',
		children: [
			{
				title: '管理员',
				key: '/sys/admin',
			},
			{
				title: '角色',
				key: '/sys/role',
			},
			{
				title: '权限',
				key: '/sys/permission',
			},
		],
	},
];

const PageModel = props => {
	const [form] = Form.useForm();
	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);
	const [permissionCheckedKeys, setPermissionCheckedKeys] = useState([]);

	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/permission/list',
			data
		});
		if(!result) return;
		const list = result.data.list.map((lst, idx) => {
			lst.key = Math.random() * 100 * (idx + 1);
			return lst;
		});
		setList(list);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [value, setValue] = useState();
	const onChange = (newValue) => {
		console.log(newValue);
		setValue(newValue);
	};

	const submitHandle = async (data, callback) => {
		console.log('====',permissionCheckedKeys);
		let array = permissionCheckedKeys,ary=[];
		for (let index = 0; index < array.length; index++) {
			const element = array[index];
			if (element.indexOf('/') == 0){
				ary.push(element)
			}
		}
		let url = '/admin/permission/add';
		if(tempFormValues && tempFormValues.id) {
			data.id = tempFormValues.id;
			url = '/admin/permission/update';
		}
		data.targetPage = ary;
		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/permission/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setTimeout(() => {
			setModalFlag(false);
		}, 20);
	};

	const [ userInfo, setUserInfo ] = useState({});

	return (
		<Page {...{
			syncUserInfo: data => {
				setUserInfo(data);
			},
			title: "权限管理",
			breadcrumbs: [{ text: '首页' }, { text: '系统设置' }, { text: '权限管理' }],
			...props
		}}>
			<section className="setting-admin-container">
				<div className="list-container">
					<FilterForm {...{
						fields: [
							{ type: 'input', label: '权限名称', name: 's-name', placeholder: '请输入' },
						],
						filterHandle: data => {
							getList(data);
						},
						extra: {
							btnText: '新增权限',
							actionHandle: () => {
								form.resetFields();
								setTempFormValues(null);
								setModalFlag(true);
							}
						}
					}} />

					<Table
						{...{
							dataSource: list,
							columns: [
								{ title: '权限名称', dataIndex: 'name', key: 'name' },
								{ title: '权限描述', dataIndex: 'description', key: 'description' },
								
								{
									title: '操作', dataIndex: 'edit', key: 'edit', width: 140, fixed: 'right',
									render: (text, record, index) => {
										const _style = { marginBottom: 10, marginRight: 10,fontSize: 12 };
										return <>
											<Button size="small" type="primary" style={_style} onClick={() => {
												delete record.password;
												form.resetFields();
												setTempFormValues(record);
												setModalFlag(true);
											}}>编辑</Button>
										</>
									}
								},
							]
						}} />
				</div>
			</section>

			{
				!!modalFlag && <Modal {...{
					open: modalFlag,
					width: 1020,
					height:1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="权限名称" name="name">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="权限描述" name="description">
							<Input placeholder="权限的描述信息" allowClear />
						</Form.Item>

						<Form.Item label="设置权限范围" name="targetPage">
							<Tree
								fieldNames={{ title: 'title'}}
								checkable
								defaultExpandAll={true}
								onCheck={(checkedKeys) => {
									setPermissionCheckedKeys(checkedKeys);
								}}
								checkedKeys={permissionCheckedKeys}
								treeData={treeDatas}
							/>
						</Form.Item>
						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
