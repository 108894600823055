/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import LessonList from '../../components/lessonList';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const pageConfig = {
	product: {
		fields: [
			{ key: 'name', comment: '商品名称' },
			{ key: 'price', comment: '所需兑换的积分值' },
			{ key: 'cover', comment: '商品封面', type: 'img' },
			{ key: 'store', comment: '库存' },
			{ key: 'sales', comment: '销量/已兑换多少数量' }
		],
		action: '/admin/product/get',
		breadcrumbs: [ { text: '首页' }, { text: '商品管理' } ],
	},
	order: {
		fields: [
			{ key: 'number', comment: '订单号' },
			{ key: 'productName', comment: '商品名字' },
			{ key: 'price', comment: '支付积分值' },
			{ key: 'collectTime', comment: '领取时间' },
			{ key: 'remark', comment: '备注信息' },
			{ key: 'userInfo', subKey: 'nickname', comment: '用户信息' },
			{ key: 'writeOffUserInfo', subKey: 'name', comment: '核销人员' },
		],
		action: '/admin/order/get',
		breadcrumbs: [ { text: '首页' }, { text: '订单管理' } ],
	}
};

const PageModel = props => {
	const { page, id } = props.match.params;
	const targetPage = pageConfig[page] || null;

	const [ data, setData ] = useState({});
	const getData = async () => {
		if(!targetPage) return;

		const result = await Request.get({
			url: targetPage.action,
			data: { id }
		});
		if(!result) return;

		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [123]);

	if(!targetPage) return null;

	return (
		<Page {...{
			title: '商品详情',
			breadcrumbs: [ ...targetPage.breadcrumbs, { text: data && (data.number || data.id) } ],
			...props
		}}>
			<section className="detail-container">
				{
					!data && <div className="nocontent">没有找到「id为{id}」相关内容</div> ||
					targetPage.fields.map((item, idx) => {
						let content = data[item.key];
						if(content && item.subKey) {
							content = content[item.subKey];
						}

						let dom;
						if(item.type == 'video') {
							dom = (
								<div className="item-content">
									{
										content && content.length > 0 && content.map((vitem, vidx) =>(
											<video height="180" src={CONST.imgPrefix + vitem} key={vidx} autobuffer="true" controls />
										))
									}
								</div>
							)
						}else if(item.type == 'img') {
							dom = (
								<div className="item-content">
									{
										content && content.length > 0 && content.map((mitem, midx) =>(
											<img src={CONST.imgPrefix + mitem} style={{ height: 56 }} key={midx} />
										))
									}
								</div>
							)
						}else{
							if(typeof content == 'object') {
								let _dom = [];
								content && content.length > 0 && content.map((item, idx) => {
									_dom.push(<div style={{ marginBottom: content.length - 1 == idx ? 0 : 8 }}>{JSON.stringify(item)}</div>)
								});
								dom = <div className="item-content" style={{ flexDirection: 'column' }}>{_dom}</div>;
							}else{
								if(content && typeof content == 'string') {
									content = content.replace(/\n/ig, '<br />');
								}
								dom = (
									<div className="item-content" dangerouslySetInnerHTML={{ __html: content }} />
								)
							}
						}

						return (
							<div className="detail-item" key={idx}>
								<label>{item.comment}</label>
								{dom}
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageModel;
