/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Form, Select, Image,Input, Modal, DatePicker, Row, Button, Upload } from 'antd';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './index.less';

import * as CONST from '../../common/const';

const CompModel = props => {
	const { fileList = [], changeHandle = () => { }, onPreviewUrl = () => {}, action = '', accept = '', pictureLength = 8 } = props;
	const [ _fileList, _setFileList ] = useState([]);
	const initFileList = () => {
		if(fileList.length > 0) {
			const _fileList = [];
			fileList.map((item, idx) => {
				const url = item;
				const name = (url => {
					const urlArr = url.split('/');
					return urlArr[urlArr.length - 1];
				})(url);
				_fileList.push({
					url: CONST.imgPrefix + url,
					src: url,
					uid: String(Math.random()) + idx,
					percent: 50,
					name,
					status: 'done'
				})
			});
			_setFileList(_fileList);
		}
	};
	useEffect(() => {
		initFileList();
	}, [ fileList ]);
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return (
		<Upload {...{
			accept,
			action,
			listType: 'picture-card',
			fileList: _fileList,
			beforeUpload(file) {
				// 只对图片进行压缩
				if(file && file.type.indexOf('image') > -1) {
					return new Promise(resolve => {
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = () => {
							const img = document.createElement('img');
							img.src = reader.result;
							img.onload = () => {
								const canvas = document.createElement('canvas');
								let width = img.naturalWidth;
								let height = img.naturalHeight;
								let ratio = width * height / 150000;
								if(ratio > 1) {
									ratio = Math.sqrt(ratio);
								}else{
									ratio = 1;
								}
								width /= ratio;
								height /= ratio;
								canvas.width = width;
								canvas.height = height;

								const ctx = canvas.getContext('2d');
								ctx.drawImage(img, 0, 0, width, height);
								canvas.toBlob(resolve);
							};
						};
					});
				}
			},
			onPreview: (target) =>{
				console.log('--------ok-------', target);
				if (target.src.split('.')[1] == 'png' || target.src.split('.')[1] == 'jpg'){
					onPreviewUrl(target.src);
				}else{
					window.open(target.url);
				}
				
				// return <Image preview={{ visible: false }} src={target.url} height={56} width={56} onClick={() => {
				// 	// setImageVisible(true);
				// 	// setVisibleCurrentIndex(imgIdx);
				// 	// setVisibleList(item.images);
				// }} />
			},
			onRemove: (target) => {
				if (target) {
					return new Promise((resolve, reject) => {
						Modal.confirm({
							title: '删除的数据将无法找回，请慎重操作！',
							icon: <WarningOutlined style={{ color: '#E45357' }} />,
							// content: 'Bla bla ...',
							okText: '确认删除',
							cancelText: '取消',
							onOk: () => {
								const list = _fileList.filter(item => item && item.name != target.name);
								_setFileList(list);
								resolve();
							},
							onCancel: () => {
								reject();
							},
						});
					});
				}
			},
			onChange: ({ fileList }) => {
				_setFileList(fileList);
				const list = [];
				if(fileList && fileList.length > 0) {
					fileList.map(item => {
						if(item && item.src) {
							list.push(item.src);
						}else if(item && item.response && item.response.data && item.response.data.ok == 0 && item.response.data.src) {
							list.push(item.response.data.src);
						}
					});
				}
				changeHandle && changeHandle(list);
			}
		}}>
			{_fileList.length >= pictureLength ? null : uploadButton}
		</Upload>
	)
};

export default CompModel;
