/**
 * Created by jimmy on 2019/11/8.
 */

export const env = window.location.host.indexOf('yeekin.cn') > -1 ? 'product' : 'dev';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';
export const uploadPrefix = '/api/v1';
// export const imgPrefix = env == 'product' ? '/' : 'http://127.0.0.1:44999/';
export const imgPrefix = 'https://little-butler.oss-cn-shanghai.aliyuncs.com/';

export const adminTypes = { 1: '超级管理员', 2: '普通管理员', 4: '核销人员' /*  处置人员就是普通用户(这仅仅是针对admin表) 游客只能浏览大盘数据 */ };

export const problemType = {
	'hotevent': '一呼即应',
	'easyevent': '社情民意',
};

export const problemStatusMap = {
	11: '待受理',
	12: '已作废',
	21: '待立案',
	22: '不立案',
	31: '待派遣',
	32: '再派遣',
	33: '待处理',
	40: '待处理',
	41: '待结案',
	43: '退回再处理',
	42: '已结案',
	51: '退单审核中',
	52: '延期审核中',
	53: '申诉审核中'
};
export const reviewMap = { 1: '很满意', 2: '较满意', 3: '不满意' };
export const getProblemTypeList = () => {
	return Object.keys(problemType).map(status => ({
		status,
		statusLabel: problemType[status]
	}))
};

export const getProblemStatusList = () => {
	return Object.keys(problemStatusMap).map(status => ({
		status,
		statusLabel: problemStatusMap[status]
	}))
};

export const departmentMap = [
	'城运中心', '北新村','徐谢村','朱长村','白马塘村','嵩山村','杨家庄村','火星村','新谊村','周浜村','马阳村','坚强村','秀龙村','陆象村','淮海村','叙南村','叙中村','新木桥村','华益村','凌家村',
	'华府社区居委','新丰社区居委','凤溪居委','西郊半岛社区居委','宝龙社区居委','春江社区居委','瑞和锦庭社区居委','星尚湾社区居委','悦欣社区居委','金瑞苑社区居委','华腾社区居委','华新居委',
	'虹桥运管大居','工业园区','城管中队','华新镇规保办','新益物业','乐洁物业','华新保洁公司','绿化公司','社发办','河长制办公室','房管所','经发办','农服中心','安管中心','城建公司','城市服务公司中心村',
	'华悦筹备组', '悦澜筹备组', '华强筹备组'
];

export const sevenCategoryList = [
	{ code: 'Q01', label: '举报正在偷乱倒工业垃圾、建筑垃圾：指未依法取得相应资质，擅自从事收运外置工业垃圾、建筑垃圾的行为。', score: 200 },
	{ code: 'Q02', label: '举报正在无证废品收购：指未依法取得许可且未依法取得营业执照从事废品收购活动。', score: 200 },
	{ code: 'Q03', label: '举报正在非法食品加工：指未依法取得相应的食品生产经营许可从事食品生产经营活动的。', score: 200 },
	{ code: 'Q04', label: '举报正在非法焚烧桔杆、垃圾：指将农作物或者可燃物用火烧从而销毁的。', score: 200 },
	{ code: 'Q05', label: '举报正在违法排放工业污水：指未按照规定采取防护性措施、擅自利用私设管、逃避监管等方式排放或者输送含有有毒污染的废水、含病原体的污水或者其他水污染物。', score: 200 },
	{ code: 'Q06', label: '举报正在非法行医：指未取得《医疗机构执业许可证》或者逾期不校验《医疗机构执业许可证》擅自执业仍从事诊疗活动的。', score: 200 },
	{ code: 'Q07', label: '举报存在重大安全隐患或其它重大行政违法行为的。', score: 200 },
	{ code: 'P01', label: '12345市民呼声', score: 200 },
];

