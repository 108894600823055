/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Radio, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/product/list',
			data
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList([...result.data.list]);
	};
	useEffect(() => {
		getList({ status: 1 });
	}, [123]);

	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);
	const [ fileList, setFileList ] = useState([]);
	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setModalFlag(false);
	};
	const submitHandle = async (data, callback) => {
		let url = '/admin/product/add';
		if(tempFormValues && tempFormValues.id) {
			data.id = tempFormValues.id;
			url = '/admin/product/update';
		}
		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);
		setFileList([]);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/product/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	return (
		<Page {...{
			title: '商品管理',
			breadcrumbs: [ { text: '首页' }, { text: '商品管理' } ],
			...props
		}}>
			<section className="product-container">
				<FilterForm {...{
					fields: [
						{ type: 'select', label: '状态', name: 's-status', placeholder: '请选择', options: [{ id: 1, label: '已上架' }, { id: 2, label: '已下架' }], defaultValue: 1 },
						{ type: 'input', label: '商品名称', name: 's-name', placeholder: '请输入' },
					],
					filterHandle: data => {
						getList(data);
					},
					extra: {
						btnText: '新增商品',
						actionHandle: () => {
							form.resetFields();
							setTempFormValues(null);
							setModalFlag(true);
						}
					}
				}} />
				<Table {...{
					columns: [
						{ title: '商品名称', dataIndex: 'name', key: 'name' },
						{
							title: '所需兑换的积分值', dataIndex: 'price', key: 'price',
							render: (text, record, index) => <span>{record.price}积分</span>
						},
						{
							title: '封面图', dataIndex: 'cover', key: 'cover',
							render: (text, record, index) => {
								if(record.cover && record.cover.length > 0) {
									return <img src={CONST.imgPrefix + record.cover[0]} style={{ height: 56 }} />
								}
								return null;
							}
						},
						{ title: '库存', dataIndex: 'store', key: 'store' },
						{ title: '兑换量', dataIndex: 'sales', key: 'sales' },
						{ title: '兑换地址', dataIndex: 'address', key: 'address' },
						{
							title: '状态', dataIndex: 'status', key: 'status', width: 88,
							render: (text, record, index) => <span>{({ 1: '已上架', 2: '已下架' })[record.status]}</span>
						},
						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 180, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
								return <>
									<Button size="small" type="primary" style={_style} onClick={() => {
										props.history.push(`/detail/product/${record.id}`);
									}}>查看详情</Button>
									<Button size="small" type="primary" style={_style} onClick={() => {
										form.resetFields();
										setTempFormValues(record);
										setModalFlag(true);
									}}>编辑</Button>
									<Button danger type="text" size="small" style={_style} onClick={() => {
										Modal.confirm({
											title: '删除的数据将无法找回，请慎重操作！',
											icon: <WarningOutlined style={{ color: '#E45357' }} />,
											// content: 'Bla bla ...',
											okText: '确认删除',
											cancelText: '取消',
											onOk: () => {
												deleteHandle(record.id, () => {
													getList();
												})
											},
											onCancel: () => {
												// console.log('Cancel');
											},
										});
									}}>删除</Button>
								</>
							}
						},
					],
					dataSource: list
				}} />
			</section>

			{
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							values.cover = fileList && fileList.length > 0 && fileList || (tempFormValues && tempFormValues.cover) || [];
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="封面" name="cover">
							<UploadPictureList {...{
								pictureLength: 1,
								action: CONST.uploadPrefix + '/admin/product/upload',
								fileList: tempFormValues && tempFormValues.cover || [],
								changeHandle: fileList => {
									setFileList(fileList);
								}
							}} />
						</Form.Item>
						<Form.Item label="商品名称" name="name">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="所需兑换的积分值" name="price">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="库存" name="store">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="兑换地址" name="address">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="上下架" name="status">
							<Radio.Group>
								<Radio value={1}>上架</Radio>
								<Radio value={2}>下架</Radio>
							</Radio.Group>
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
