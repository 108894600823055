/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import * as Request from './request';
import * as CONST from './const';

export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';
	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		console.log('====_item=====', _item);
		if (_item[0] && _item[1] && _item[2]) {
			_map[_item[0]] = _item[1] + '=' + _item[2];
		}else{
			_map[_item[0]] = _item[1]
		}
	});
	return _map[key] || '';
};

export const setTitle = (title = '青浦区公安局史馆档案系统') => {
	if(document) {
		document.title = title;
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('loading').style.display = 'none';
	}
};

// toast
export const toast = {
	show: (txt) => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'block';
		$toast.innerText = txt;
	},
	hide: () => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'none';
		$toast.innerText = '';
	}
};

export const getUA = () => {
	if(!window || !window.navigator || !window.navigator.userAgent){
		return null;
	}

	const ua = window.navigator.userAgent.toLocaleLowerCase();

	return {
		ua,
		isInWechat: ua.indexOf('micromessenger') > -1,
		isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
	}
};

export const _alert = (txt) => {
	if(!txt) return;

	toast.show(txt);

	setTimeout(() => {
		toast.hide();
	}, 1500);
};

/**
 * 登录功能
 * 微信内：微信登录
 * APP 内：暂时先 return
 */
const _user = {
	getInfo: async () => {

	},
	setInfo: (info = {}) => {

	},
	login: async () => {

	}
};
export const user = _user;

export const arrayToMap = (arr = [], key = 'id', value = 'label') => {
	const map = {};
	arr.map(a => {
		map[a[key]] = a[value];
	});
	return map;
};

export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};

export const uploadHelper = {
	submitHandle: (fileList) => {
		const _fileList = [];
		fileList.map(fileInfo => {
			if(fileInfo.response && fileInfo.response.ok == 0 && fileInfo.response.data && fileInfo.response.data.src) {
				_fileList.push(fileInfo.response.data.src);
			}else if(fileInfo.src) {
				_fileList.push(fileInfo.src);
			}
		});
		return JSON.stringify(_fileList);
	},
	editDisplayHandle: (fileListStr) => {
		const _fileList = [];
		const _fileListStr = JSON.parse(fileListStr);
		if(_fileListStr && _fileListStr.length > 0) {
			_fileListStr.map((file, idx) => {
				const fileInfo = file.split('/') && file.split('/')[2] || '';
				if(fileInfo) {
					const [ name, suffix ] = fileInfo.split('.');
					const [ fileName ] = name.split('-');
					_fileList.push({
						uid: idx,
						name: fileName,
						status: 'done',
						url: CONST.imgPrefix + file,
						src: file
					});
				}
			})
		}
		return _fileList;
	}
};

export const niceStr = str => {
	if(!str) return '';
	str = str.replace(/[0-9]+-/ig, '').replace(/\.jpg/ig, '').replace(/\.png/ig, '');
	return str;
};

export const beautyTableColunm = (conf = {}) => {
	const childrenRender = conf.children || null;
	delete conf.children;

	return {
		width: 420,
		render: (text, record, index) => {
			const style = { maxHeight: 88, overflow: 'auto' }
			if(childrenRender && typeof childrenRender == 'function') {
				return (
					<div style={style}>{ childrenRender(record)}</div>
				)
			}else{
				text = text ? text.replace(/\n/ig, '<br />') : text;
				return (
					<div style={style} dangerouslySetInnerHTML={{ __html: text }} />
				)
			}
		},
		...conf
	}
};

export const isSuperAdmin = mobile => {
	return !![ '15221908784', '13764970369', '15212345678', '13918244816', 'hxwghxy001', '18918691049', '13661706089','18516212561' ].includes(mobile);
}
