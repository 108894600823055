/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const startDate = moment().subtract(7, 'd');
	const endDate = moment();
	const dateFormat = 'YYYY-MM-DD';

	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		if(data.changetime){
			const _changetime = data.changetime;
			const start = _changetime[0] && moment(_changetime[0]).format('YYYY-MM-DD');
			const end = _changetime[1] && moment(_changetime[1]).format('YYYY-MM-DD');
			data.changetime = `${start},${end}`;
		}

		const result = await Request.get({
			url: '/admin/score/department/list',
			data
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList(result.data.list);
	};
	useEffect(() => {
		getList({
			changetime: [ startDate, endDate ]
		});
	}, [ 123 ]);

	const [ userInfo, setUserInfo ] = useState({});
console.log('---moment().add(-1)--', moment().subtract(-1).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));


	return (
		<Page {...{
			title: '积分管理',
			breadcrumbs: [ { text: '首页' }, { text: '积分管理' } ],
			syncUserInfo: data => {
				setUserInfo(data)
			},
			...props
		}}>
			<section className="score-container">
				<FilterForm {...{
					fields: [
						// { type: 'input', label: '用户名称', name: 's-nickname', placeholder: '请输入' },
						// { type: 'select', label: '所在村居', name: 's-department', placeholder: '请选择', options: CONST.departmentMap.map(item => ({ id: item, label: item })) },
						{
							type: 'range-date', label: '选择积分变更时间', name: 's-changetime', placeholder: [ '请输入起始时间', '请输入结束时间' ], style: { width: 400 },
							defaultValue: [ startDate, endDate ],
							format: dateFormat
						},
					],
					filterHandle: data => {
						getList(data);
					},
					exportHandle: async data => {
						if(data.changetime){
							const _changetime = data.changetime;
							const start = _changetime[0] && moment(_changetime[0]).format('YYYY-MM-DD');
							const end = _changetime[1] && moment(_changetime[1]).format('YYYY-MM-DD');
							data.changetime = `${start},${end}`;
						}else{
							data.changetime = `${startDate.format(dateFormat)},${endDate.format(dateFormat)}`
						}

						const result = await Request.get({
							url: '/admin/score/department/list/export',
							data
						});
						if(!result) return;
						window.open(result.data.path);
					},
					// extra: {
					// 	btnText: '新增积分',
					// 	actionHandle: () => {
					// 		form.resetFields();
					// 		setTempFormValues(null);
					// 		setModalFlag(true);
					// 	}
					// }
				}} />

				<Table {...{
					size: 'small',
					dataSource: list,
					columns: [
						{ title: '村居', dataIndex: 'department', key: 'department', },
						{ title: '居民数量', dataIndex: 'userCount', key: 'userCount', },
						{ title: '获得积分', dataIndex: 'scoreAdd', key: 'scoreAdd', },
						{ title: '消耗积分', dataIndex: 'scoreMinus', key: 'scoreMinus', },
						{ title: '累计积分', dataIndex: 'scoreTotal', key: 'scoreTotal', },
					],
					pagination: false
				}} />
			</section>
		</Page>
	);
};

export default PageModel;
