/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Link,useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import * as Request from '../../common/request';
import './index.less';
import { Menu } from 'antd';
import { DatabaseOutlined, GatewayOutlined, MessageOutlined, MacCommandOutlined, ProfileOutlined, UserOutlined, EuroOutlined } from '@ant-design/icons';
import * as Utils from '../../common/utils';

const MENU = [
	{
		label: '数据分析', key: 'dashboard', icon: <DatabaseOutlined />,
		children: [
			{ label: '网格化事件', key: '/dashboard' },
			{ label: '一呼即应', key: '/dashboardhot' },
			{ label: '电话接打记录', key: '/dashboardlist' },
		]
	},
	{
		label: '案件派遣', key: 'problem3', icon: <GatewayOutlined />,
		children: [
			{ label: '待派遣', key: '/problem?status=31' },
			{ label: '再派遣', key: '/problem?status=32' },
			{ label: '管控', key: '/problem?status=33' },
			{ label: '归档', key: '/problem?status=12' }
		]
	},
	{
		label: '案件结案', key: 'problem4', icon: <GatewayOutlined />,
		children: [
			{ label: '事件处理', key: '/problem?status=40' },
			{ label: '待结案', key: '/problem?status=41' },
			{ label: '已结案', key: '/problem?status=42' },
		]
	},
	{
		label: '案件督办', key: 'problem5', icon: <GatewayOutlined />,
		children: [
			{ label: '退单', key: '/problem?status=51' },
			{ label: '延期', key: '/problem?status=52' },
			{ label: '申诉', key: '/problem?status=53' },
		]
	},
	{ 
		label: '市民留言', key: 'message', icon: <MessageOutlined />,
		children:[
			{ label: '留言信息', key:'/message'}
		]
	},
	{
		label: '积分商城', key: 'product', icon: <MacCommandOutlined />,
		children: [
			{ label: '积分商城', key: '/product' }
		]
	},
	{
		label: '积分明细', key: 'score', icon: <EuroOutlined />,
		children: [
			{ label: '市民积分', key: '/score' },
			{ label: '村居积分', key: '/dscore' }
		]
	},
	{
		label: '兑换订单', key: 'order', icon: <ProfileOutlined />,
		children: [
			{ label: '兑换订单', key: '/order' }
		]
	},
	{
		label: '用户信息', key: 'user', icon: <UserOutlined />,
		children: [
			{ label: '处置人员', key: '/worker'},
			{ label: '普通用户', key: '/user'},
		]
	},
	{
		label: '系统管理', key: 'system', icon: <GatewayOutlined />,
		children: [
			{ label: '管理员', key: '/sys/admin',},
			{ label: '角色', key: '/sys/role',},
			{ label: '权限', key: '/sys/permission',},
		]
	}
]
const _BASENAVS = [
	{ txt: '数据分析', icon: <DatabaseOutlined />, to: '/dashboard', key: 'dashboard',
		children: [
			{ txt: '网格化事件', key: 'dashboard1', to: '/dashboard' },
			{ txt: '一呼即应', key: 'dashboard2', to: '/dashboardhot' },
		]
	},
	{
		txt: '案件管理', icon: <GatewayOutlined />, to: '', key: 'problem',
		children: [
			{
				txt: '案件派遣', to: '', key: 'problem3',
				children: [ 
					{ txt: '待派遣', to: '/problem?status=31', key: 'problem31' }, 
					{ txt: '再派遣', to: '/problem?status=32', key: 'problem32' }, 
					{ txt: '管控', to: '/problem?status=33', key: 'problem33' },
					{ txt: '归档', to: '/problem?status=12', key: 'problem12' },
				]
			},
			{
				txt: '案件结案', to: '', key: 'problem4',
				children: [ 
					{ txt: '待结案', to: '/problem?status=41', key: 'problem41' }, 
					{ txt: '已结案', to: '/problem?status=42', key: 'problem42' } 
				]
			},
			{
				txt: '案件督办', to: '', key: 'problem5',
				children: [ 
					{ txt: '退单', to: '/problem?status=51', key: 'problem51' }, 
					{ txt: '延期', to: '/problem?status=52', key: 'problem52' }, 
					{ txt: '申诉', to: '/problem?status=53', key: 'problem53' } 
				]
			}
		]
	},
	{ txt: '市民留言', icon: <MessageOutlined />, to: '/message', key: 'message' },
	{ txt: '积分商城', icon: <MacCommandOutlined />, to: '/product', key: 'product' },
	{ txt: '兑换订单', icon: <ProfileOutlined />, to: '/order', key: 'order' },
	{
		txt: '用户信息', icon: <UserOutlined />, to: '/user', key: 'user',
		children: [
			{
				txt: '处置人员', key: 'user2', to: '/worker',
			},
			{
				txt: '普通用户', key: 'user1', to: '/user'
			},
		]
	},
	{
		txt: '积分明细', icon: <EuroOutlined />, to: '/score', key: 'score',
		children: [
			{ txt: '市民积分', key: 'score1', to: '/score' },
			{ txt: '村居积分', key: 'score2', to: '/dscore' },
		]
	},
	{
		txt: '系统管理', icon: <GatewayOutlined />, to: 'administrator', key: 'system',
		children: [
			{ txt: '管理员', to: '/sys/admin', key: 'administrator1' },
			{ txt: '角色', to: '/sys/admin', key: 'administrator2' },
			{ txt: '权限', to: '/sys/admin', key: 'administrator3' },
		]
	}
];
const addKeyToLink = (link, key) => {
	const nok1 = link.indexOf('?_k=') == -1;
	const nok2 = link.indexOf('&_k=') == -1;
	if(nok1 && nok2){
		if(link.indexOf('?') == -1) {
			link += `?_k=${key}`;
		}else{
			link += `&_k=${key}`;
		}
	}

	return link;
};

const { SubMenu } = Menu;
const CompModel = props => {
	const location = useLocation();
	const history = useHistory();
	const [collapsed, setCollapsed] = useState(false);

	const [menus, setMenus] = useState([]);
	const [user, setUser] = useState(null);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [openKeys, setOpenKeys] = useState([]);
	const [ defaultSelectedKeys, setDefaultSelectedKeys ] = useState([]);
	const [ defaultOpenKeys, setDefaultOpenKeys ] = useState([]);
	
	const initMenu = () => {
		const _k = Utils.getSearchByKey('_k');
		defaultSelectedKeys.push(_k);
		if(_k.indexOf('problem') > -1) {
			const __k = _k.slice(0, -1);
			defaultOpenKeys.push('problem');
			defaultOpenKeys.push(__k);
		}
		if(_k.indexOf('user') > -1) {
			const __k = _k.slice(0, -1);
			defaultOpenKeys.push('user');
			defaultOpenKeys.push(__k);
		}
		if(_k.indexOf('score') > -1) {
			const __k = _k.slice(0, -1);
			defaultOpenKeys.push('score');
			defaultOpenKeys.push(__k);
		}
		if (_k.indexOf('dashboard') > -1) {
			const __k = _k.slice(0, -1);
			defaultOpenKeys.push('dashboard');
			defaultOpenKeys.push(__k);
		}
		if (_k.indexOf('administrator') > -1) {
			const __k = _k.slice(0, -1);
			defaultOpenKeys.push('administrator');
			defaultOpenKeys.push(__k);
		}
		setDefaultSelectedKeys(defaultSelectedKeys);
		setDefaultOpenKeys(defaultOpenKeys);
	}
	const getUser = async () => {
		const result = await Request.get({
			url: '/admin/admin/get'
		});
		const userInfo = result.data;
		const menus = [];
		console.log('menu==',result.data);
		if (userInfo?.targetPage?.length > 0) {
			MENU.map(menuItem => {
				if (menuItem?.children?.length > 0) {
					const _children = [];
					menuItem.children.map(citem => {
						if (userInfo.targetPage.includes(citem.key)) {
							_children.push(citem);
						}
					});
					if (_children?.length > 0) {
						menuItem.children = _children;
						menus.push(menuItem);
					}
				} else {
					if (userInfo.targetPage.includes(menuItem.key)) {
						menus.push(menuItem);
					}
				}
			});
		}

		console.log('====',menus);
		setMenus(menus);
	};
	useEffect(() => {
		if (menus?.length <= 0) return;

		let search = location.search;
		let selectedKey = location.pathname;
		const _selectedKey = selectedKey.replace(/\/[0-9]+/g, "");
		let openKey = menus[0].key;
		if (selectedKey == '/' || selectedKey == '') {
			selectedKey = menus[0].children[0].key || menus[0].key;
		} else {
			selectedKey = selectedKey || menus[0].children[0].key || menus[0].key;
			openKey = (() => {
				let target = menus[0];
				for (let i = 0, len = menus.length; i < len; i++) {
					const menuItem = menus[i];
					if (menuItem?.children?.length > 0) {
						for (let ci = 0, clen = menuItem.children?.length; ci < clen; ci++) {
							if (menuItem.children[ci].key == selectedKey || (menuItem.children[ci].key.indexOf(_selectedKey) > -1 && menuItem.children[ci].key == selectedKey+search)) {
								target = menuItem;
								selectedKey = menuItem.children[ci].key;
							}
						}
					} else if (menuItem?.key == selectedKey || menuItem?.key.indexOf(_selectedKey) > -1) {
						target = menuItem;
						selectedKey = menuItem.key;
					}
				}

				return target?.key || null;
			})();
		}
		setSelectedKeys([openKey, selectedKey]);
		setOpenKeys([openKey]);
	}, [menus]);

	useEffect(() => {
		getUser();
		// initMenu();
	}, [ window.location.href ]);
	useEffect(() => {
		// props.collapsedHandle(collapsed);
	}, [collapsed]);
	return (
		<Menu {...{
			style: { overflow: 'hidden', border: 0, width: '100%', backgroundColor: '#2C2E47' },
			mode: 'inline',
			theme: 'dark',
			openKeys,
			selectedKeys,
			inlineCollapsed: collapsed,
			items: menus,
			onClick: data => {
				setSelectedKeys([data.key]);
				history.push(`${data.key}`);
			},
			onOpenChange: openKeys => {
				setOpenKeys(openKeys);
			}
		}} />
		// <Menu
		// 	style={{ width: 168, backgroundColor: '#2C2E47' }}
		// 	defaultOpenKeys={defaultOpenKeys}
		// 	defaultSelectedKeys={defaultSelectedKeys}
		// 	theme="dark"
		// 	mode="inline"
		// >
		// 	{
		// 		_BASENAVS.map((item, idx) => {
		// 			item.to = addKeyToLink(item.to, item.key);
		// 			if(item.children && item.children.length > 0) {
		// 				return (
		// 					<SubMenu key={item.key} icon={item.icon} title={item.txt}>
		// 						{
		// 							item.children.map((_item, _idx) => {
		// 								_item.to = addKeyToLink(_item.to, _item.key);
		// 								if(_item.children && _item.children.length > 0) {
		// 									return (
		// 										<SubMenu key={_item.key} title={_item.txt}>
		// 											{
		// 												_item.children.map((__item, __idx) => {
		// 													__item.to = addKeyToLink(__item.to, __item.key);
		// 													return (
		// 														<Menu.Item key={__item.key} onClick={() => {
		// 															if(__item.to) props.history.push(__item.to);
		// 														}}>{__item.txt}</Menu.Item>
		// 													)
		// 												})
		// 											}
		// 										</SubMenu>
		// 									)
		// 								}else {
		// 									return (
		// 										<Menu.Item key={_item.key} onClick={() => {
		// 											if(_item.to) props.history.push(_item.to);
		// 										}}>{_item.txt}</Menu.Item>
		// 									)
		// 								}
		// 							})
		// 						}
		// 					</SubMenu>
		// 				)
		// 			}else {
		// 				return (
		// 					<Menu.Item key={item.key} icon={item.icon} onClick={() => {
		// 						if(item.to) props.history.push(item.to);
		// 					}}>{item.txt}</Menu.Item>
		// 				)
		// 			}
		// 		})
		// 	}
		// </Menu>
	)
};

export default CompModel;
