/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Card, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload, Statistic } from 'antd';
import { Line, Pie, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, CaretUpOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import LessonList from '../../components/lessonList';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [ caseCount, setCaseCount ] = useState({});
	const [ userCount, setUserCount ] = useState({});
	const [ productCount, setProductCount ] = useState({});
	const [ orderCount, setOrderCount ] = useState({});
	const getCount = async (url, callback) => {
		const result = await Request.get({
			url
		});
		if(!result) return;

		callback && typeof callback == 'function' && callback(result.data);
	};
	useEffect(() => {
		getCount('/admin/stat/case', setCaseCount);
		getCount('/admin/stat/user', setUserCount);
		getCount('/admin/stat/product', setProductCount);
		getCount('/admin/stat/order', setOrderCount);
	}, [123]);

	const [ departmentCaseList, setDepartmentCaseList ] = useState([]);
	const getDepartmentCaseList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/department-case/list',
			data
		});
		if(!result) return;

		setDepartmentCaseList(result.data.list);
	};
	useEffect(() => {
		getDepartmentCaseList();
	}, [123]);

	const [ categoryCaseList, setCategoryCaseList ] = useState([]);
	const getCategoryCaseList = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/category-case/list',
			data
		});
		if(!result) return;

		const map = {};
		result.data.list.map(item => {
			if(item.categoryLabel) {
				map[item.categoryLabel] = map[item.categoryLabel] || 0;
				map[item.categoryLabel] += Number(item.count);
			}
			// item.type = item.categoryLabel;
			// item.value = item.count;
			// return item;
		});
		const list = [];
		Object.keys(map).map(type => {
			list.push({
				type,
				value: map[type]
			});
		});
		setCategoryCaseList(list);
		console.log('list', list);
	};
	useEffect(() => {
		getCategoryCaseList();
	}, [123]);

	// const [ categoryScoreList, setCategoryScoreList ] = useState([]);
	// const getCategoryScoreList = async (data = {}) => {
	// 	const result = await Request.get({
	// 		url: '/admin/stat/category-score/list',
	// 		data
	// 	});
	// 	if(!result) return;
	//
	// 	setCategoryScoreList(result.data.list);
	// };
	// useEffect(() => {
	// 	getCategoryScoreList();
	// }, [123]);

	const [ scoreRadioList, setScoreRadioList ] = useState([]);
	const getScoreRadio = async (data = {}) => {
		const result = await Request.get({
			url: '/admin/stat/score-exchange/radio',
			data
		});
		if(!result) return;

		const list = [
			{
				type: '已兑换积分',
				value: result.data.used,
			},
			{
				type: '待兑换积分',
				value: result.data.total,
			},
		]
		setScoreRadioList(list);
	};
	useEffect(() => {
		getScoreRadio();
	}, [123]);

	const selDate = (date, dateString) => {
		console.log(dateString);
		getDepartmentCaseList({ "time": `${dateString[0]}`+','+`${dateString[1]}`})
	}

	
	return (
		<Page {...{
			title: '数据分析',
			breadcrumbs: [ { text: '首页' }, { text: '数据分析' } ],
			...props
		}}>
			<section className="dashboard-container">
				<div className="column-left">
					<div style={{ display: 'flex' }}>
						<div className="chart-block" style={{ height: 308 }}>
							<div className="chart-title">
								<span>村居案件分布图</span>
								<div className="time-range">
									{/* <span>本周</span> */}
									{/* <span>本月</span> */}
									{/* <span>本年</span> */}
									<DatePicker.RangePicker placeholder={['起始时间', '结束时间']} style={{ marginLeft: 10 }} onChange={selDate} />
								</div>
							</div>
							<Line {...{
								data: departmentCaseList,
								padding: 'auto',
								xField: 'processorName',
								yField: 'count',
								xAxis: {
									tickCount: 5,
								},
								slider: {
									start: 0.1,
									end: 0.5,
								}
							}} />
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div className="chart-block">
							<div className="chart-title">案件类型分布图</div>
							<Pie {...{
								appendPadding: 10,
								data: categoryCaseList,
								angleField: 'value',
								colorField: 'type',
								radius: 1,
								innerRadius: 0.5,
								// tooltip: false,
								// legend: false,
								label: {
									type: 'outer',
									// type: 'inner',
									// offset: '-50%',
									content: '{name} {value}',
									// style: {
									// 	textAlign: 'center',
									// 	fontSize: 14,
									// },
								},
								interactions: [
									{
										type: 'element-selected',
									},
									{
										value: 'element-active',
									},
								],
								statistic: null,
							}} />
						</div>
						<div className="chart-block" style={{}}>
							<div className="chart-title">积分池与兑换积分占比</div>
							<Pie {...{
								appendPadding: 10,
								data: scoreRadioList,
								angleField: 'value',
								colorField: 'type',
								label: {
									type: 'outer',
								},
								interactions: [
									{
										type: 'element-active',
									},
								],
							}} />
						</div>
					</div>
					{/*<div style={{ display: 'flex' }}>
						<div className="chart-block" style={{ height: 555 }}>
							<div className="chart-title">村居积分累计对照表</div>
							<Table {...{
								size: 'small',
								dataSource: categoryScoreList,
								columns: [
									{ title: '村居', dataIndex: 'department', key: 'department', },
									{ title: '居民数量', dataIndex: 'userCount', key: 'userCount', },
									{ title: '获得积分', dataIndex: 'scoreAdd', key: 'scoreAdd', },
									{ title: '消耗积分', dataIndex: 'scoreMinus', key: 'scoreMinus', },
									{ title: '累计积分', dataIndex: 'scoreTotal', key: 'scoreTotal', },
								],
								pagination: false,
								scroll: {
									y: 454
								}
							}} />
						</div>
					</div>*/}
					{/*<div style={{ display: 'flex' }}>
						<div className="chart-block" style={{}}>
							<div className="chart-title">热门留言排行（点赞数居前20）</div>
							<Table {...{
								size: 'small',
								dataSource: [
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 42,
										address: '西湖区湖底公园1号',
									},
									{
										key: '1',
										name: '胡彦斌',
										age: 32,
										address: '西湖区湖底公园1号',
									},
									{
										key: '2',
										name: '胡彦祖',
										age: 412,
										address: '西湖区湖底公园1号',
									},
								],
								columns: [
									{
										title: '姓名',
										dataIndex: 'name',
										key: 'name',
									},
									{
										title: '年龄',
										dataIndex: 'age',
										key: 'age',
									},
									{
										title: '住址',
										dataIndex: 'address',
										key: 'address',
									},
								],
								pagination: false,
								scroll: {
									y: 187
								}
							}} />
						</div>
						<div className="chart-block" style={{}}>
							<div className="chart-title">最受欢迎商品排行（按兑换量）</div>
							<Column {...{
								data: [
									{
										type: '家具家电',
										sales: 38,
									},
									{
										type: '粮油副食',
										sales: 52,
									},
									{
										type: '生鲜水果',
										sales: 61,
									},
									{
										type: '美容洗护',
										sales: 145,
									},
									{
										type: '母婴用品',
										sales: 48,
									},
									{
										type: '进口食品',
										sales: 38,
									},
									{
										type: '食品饮料',
										sales: 38,
									},
									{
										type: '家庭清洁',
										sales: 38,
									},
								],
								xField: 'type',
								yField: 'sales',
								label: {
									// 可手动配置 label 数据标签位置
									position: 'middle',
									// 'top', 'bottom', 'middle',
									// 配置样式
									style: {
										fill: '#FFFFFF',
										opacity: 0.6,
									},
								},
								xAxis: {
									label: {
										autoHide: true,
										autoRotate: false,
									},
								},
								meta: {
									type: {
										alias: '类别',
									},
									sales: {
										alias: '销售额',
									},
								}
							}} />
						</div>
					</div>*/}
				</div>
				<div className="column-right">
					<div className="important-datas">
						<div className="item"><label>立案数</label><span>{caseCount.lianCount || '暂无'}</span></div>
						<div className="item"><label>结案数</label><span>{caseCount.jieanCount || '暂无'}</span></div>
						<div className="item"><label>结案率</label><span><CaretUpOutlined style={{ marginRight: 4, color: 'red', display: 'none' }} />{caseCount.radio || '暂无'}</span></div>
						<div className="item"><label>市民满意度</label><span>{caseCount.manyiRadio || '暂无'}</span></div>
					</div>
					<div className="data-title">用户数据</div>
					<div className="data-item">
						<div className="item"><label>用户数</label><span>{userCount.userCount || '暂无'}</span></div>
						<div className="item"><label>用户留言数</label><span>{userCount.userMsgCount || '暂无'}</span></div>
					</div>
					<div className="data-title">处置人员数据</div>
					<div className="data-item">
						<div className="item"><label>处置人员数</label><span>{userCount.workerUserCount || '暂无'}</span></div>
					</div>
					<div className="data-title">商品数据</div>
					<div className="data-item">
						<div className="item"><label>商品数</label><span>{productCount.productCount || '暂无'}</span></div>
						<div className="item"><label>商品兑换率</label><span>{productCount.productRadio || '暂无'}</span></div>
						<div className="item"><label>订单数</label><span>{orderCount.orderCount || '暂无'}</span></div>
						<div className="item"><label>订单兑换总积分</label><span>{orderCount.scoreCount || '暂无'}</span></div>
					</div>
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
