/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Popover, Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col } from 'antd';

// import watermark from 'watermark-dom'


import './index.less';
import * as Utils from '../../common/utils';
import * as Request from '../../common/request';

import Nav from '../nav';
import Logo from '../logo';

const CompModel = props => {
	const [popoverFlag, setPopoverFlag] = useState(false);
	const [visible, setVisible] = useState(false);

	const [ user, setUser ] = useState(null);
	const getUser = async () => {
		const result = await Request.get({
			url: '/admin/admin/get'
		});
		if(!result) return;
		setUser(result.data);
		// watermark.load({ watermark_txt: result.data.name })

		if(props.syncUserInfo && typeof props.syncUserInfo == 'function') {
			props.syncUserInfo(result.data);
		}
	};
	useEffect(() => {
		getUser();
		

		Utils.setTitle(props.title);
	}, [123]);

	const cancelHandle = () => {
		setVisible(false);
	};

	const logout = async () => {
		const result = await Request.post({
			url: '/admin/admin/logout'
		});
		if(!result) return;
		window.location.href = '/login?redirectUrl=' + window.location.href;
	};

	const updatapsd = async (data) => {
		const result = await Request.post({
			url: '/admin/admin/updatapsd',
			data:{
				...data,
				id:window.localStorage.getItem("id")
			}
		});
		if (result){
			message.success('密码已修改,重新登陆');
			window.location.href = '/login?redirectUrl=' + window.location.href;
		};

	};

	return (
		<section className="container" style={{ minHeight: window.innerHeight }}>
			
			<div className="sidebar" style={{ height: window.innerHeight, overflow: 'auto' }}>
				<Logo style={{
					marginTop: 18,
					marginRight: 'auto',
					marginBottom: 38,
					marginLeft: 'auto'
				}} />
				<Nav {...props} />
			</div>
			<div className="body" style={{ height: window.innerHeight, overflow: 'auto' }}>
				<div className="header">
					<span></span>


					<Popover
						content={<div>
							<div>
								<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
									setVisible(true);
								}}>修改密码</span>
							</div>
							<div>
								<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
									logout();
								}}>登出</span>
							</div>
						</div>}
						trigger="hover"
						style={{ cursor: 'pointer' }}
						visible={popoverFlag}
						onVisibleChange={() => { setPopoverFlag(!popoverFlag) }}
					>
						<div className="user"><div className="avatar" /><span className="name">{user && user.name || '未登录'}</span></div>
					</Popover>
					
				</div>
				{
					!!visible && <Modal {...{
						open: visible,
						width: 500,
						footer: null,
						maskClosable: false,
						onCancel: cancelHandle
					}}>
						<Form {...{
							labelCol: { span: 6 },
							wrapperCol: { span: 16 },
							onFinish: values => {
								console.log('value==',values);
								if(values.psd != values.newpsd){
									message.error('前后输入保持一致');
									return;
								}
								updatapsd(values)
								// submitHandle(values, () => {
									// setModalFlag(false);
									// getList();
								// });
							}
						}}>
							<Form.Item label="新密码" name="psd">
								<Input placeholder="请输入" allowClear />
							</Form.Item>
							<Form.Item label="再次输入" name="newpsd">
								<Input placeholder="请输入" allowClear />
							</Form.Item>

							<Form.Item label=" " colon={false}>
								<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
								<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
							</Form.Item>
						</Form>
					</Modal>
				}
				{
					props.breadcrumbs && props.breadcrumbs.length > 0 && <div className="bread-container">
						<Breadcrumb>
							{
								props.breadcrumbs.map((item, idx) => {
									const txtDom = !!item.link ? <Link to={item.link}>{item.text}</Link> : item.text;
									return (
										<Breadcrumb.Item key={idx}>{txtDom}</Breadcrumb.Item>
									)
								})
							}
						</Breadcrumb>
					</div>
				}
				<div className="main-container">{props.children}</div>
			</div>
		</section>
	);
};

export default CompModel;
