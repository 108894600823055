/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, message, Row, Col, Drawer, Alert, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Logo from '../../components/logo';

import './index.less';

const PageAbout = props => {
	const [form] = Form.useForm();

	const loginPlus = async (token) =>{
		let data = {token:token}
		if(token){
			const result = await Request.post({
				url: '/admin/admin/loginplus',
				data
			});
			if (!result) return;

			message.success('登录成功');
			const redirectUrl = Utils.getSearchByKey('redirectUrl');
			if (redirectUrl) {
				window.location.href = decodeURIComponent(redirectUrl);
				return
			} else {
				window.location.href = '/problem?status=40&_k=problem40';
			}

		}
	}
	//获取url链接上拼接的参数
	useEffect(() => {
		let url_data = queryURLParams(window.location.href);
		loginPlus(url_data[""]);
	}, [123]);


	const queryURLParams = (URL) => {
		let url = URL.split("?")[1];
		let obj = {}; // 声明参数对象
		let arr =url ? url.split("&") : []; // 以&符号分割为数组
		for (let i = 0; i < arr.length; i++) {
			let arrNew = arr[i].split("q="); // 以"="分割为数组
			obj[arrNew[0]] = arrNew[1];
		}
		return obj;
	}

	const loginHandle = async (data = {}) => {
		const result = await Request.post({
			url: '/admin/admin/login',
			data
		});
		if(!result) return;
		message.success('登录成功');
		window.localStorage.setItem("id", result.data.id);
		window.localStorage.setItem("type", result.data.type);
		const redirectUrl = Utils.getSearchByKey('redirectUrl');
		
		if (redirectUrl && result.data.type != 2) {
			window.location.href = '/dashboard';
			return
		}else{
			window.location.href = '/problem?status=40&_k=problem40';
		}
	};

	return (
		<section className="login-container" style={{ minHeight: window.innerHeight }}>
			<div className="header">
				<Logo />
				<span>欢迎使用"管达人"后台系统</span>
			</div>
			<div className="login-wrap">
				<Form {...{
					form,
					onFinish: values => {
						loginHandle(values);
					}
				}}>
					<Form.Item label="" name="name">
						<Input placeholder="用户名/手机号" size="large" prefix={<UserOutlined />} />
					</Form.Item>
					<Form.Item label="" name="password" style={{ marginBottom: 0 }}>
						<Input.Password placeholder="请输入" size="large" prefix={<LockOutlined />} />
					</Form.Item>
					<div className="helps">
						<span />
						{/*<Form.Item label="" name="remember" style={{ marginBottom: 0 }}>*/}
							{/*<Checkbox>记住我</Checkbox>*/}
						{/*</Form.Item>*/}
						<span className="forget" onClick={() => {
							message.warn('请联系管理员重置密码');
						}}>忘记密码？</span>
					</div>
					<Form.Item label="" colon={false}>
						<Button type="primary" size="large" block htmlType="submit">登录</Button>
					</Form.Item>
				</Form>
			</div>
		</section>
	);
};

export default PageAbout;
