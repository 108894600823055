/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [ listQuery, setListQuery ] = useState({});
	const [ page, setPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState(10);
	const [ pageTotal, setPageTotal ] = useState(0);
	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		data.page = page - 1;
		data.pageSize = pageSize;
		const result = await Request.get({
			url: '/admin/order/list',
			data: {
				...listQuery,
				...data
			}
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList([...result.data.list]);
		setPageTotal(result.data.count);
	};
	useEffect(() => {
		getList();
	}, [ page, listQuery ]);

	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);
	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setModalFlag(false);
	};
	const submitHandle = async (data, callback) => {
		const result = await Request.post({
			url: '/admin/order/collect',
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);

		callback && typeof callback == 'function' && callback();
	};

	return (
		<Page {...{
			title: '订单管理',
			breadcrumbs: [ { text: '首页' }, { text: '订单管理' } ],
			...props
		}}>
			<section className="order-container">
				<FilterForm {...{
					fields: [
						{ type: 'input', label: '兑换商品名称', name: 's-productName', placeholder: '请输入' },
						{ type: 'input', label: '用户名称', name: 's-nickname', placeholder: '请输入' },
						{ type: 'select', label: '状态', name: 's-status', placeholder: '请选择', options: [{ id: 1, label: '待领取' }, { id: 2, label: '已领取' }] },
						{ type: 'range-date', label: '选择兑换时间', name: 's-exchangetime', placeholder: [ '请输入起始时间', '请输入结束时间' ], style: { width: 400 } },
					],
					filterHandle: data => {
						setListQuery({...data});
						setPage(1);
						// getList(data);
					},
					exportHandle: async data => {
						const result = await Request.get({
							url: '/admin/order/list/export',
							data
						});
						if(!result) return;
						window.open(result.data.path);
					},
				}} />

				<Table {...{
					columns: [
						{ title: '订单编号', dataIndex: 'number', key: 'number' },
						{
							title: '所需兑换的积分值', dataIndex: 'price', key: 'price',
							render: (text, record, index) => <span>{record.price}积分</span>
						},
						{ title: '兑换商品名称', dataIndex: 'productName', key: 'productName' },
						{
							title: '兑换时间', dataIndex: 'createdAt', key: 'createdAt',
							render: (text, record, index) => <span>{record.createdAt && moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</span>
						},
						{
							title: '领取时间', dataIndex: 'collectTime', key: 'collectTime',
						},
						{
							title: '订单状态', dataIndex: 'status', key: 'status',
							render: (text, record, index) => <span>{({ 1: '待领取', 2: '已领取' })[record.status]}</span>
						},
						{
							title: '用户名称', dataIndex: 'nickname', key: 'nickname',
							render: (text, record, index) => {
								let str = record.userInfo && record.userInfo.nickname;
								if(record.userInfo && record.userInfo.department) {
									str += `（${record.userInfo.department}）`;
								}
								return <span>{str}</span>
							}
						},

						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 180, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
								return <>
									<Button size="small" type="primary" style={_style} onClick={() => {
										props.history.push(`/detail/order/${record.id}`);
									}}>查看详情</Button>
									<Button size="small" type="primary" style={_style} disabled={record.status == 2} onClick={() => {
										form.resetFields();
										setTempFormValues(record);
										setModalFlag(true);
									}}>办理领取</Button>
								</>
							}
						},
					],
					dataSource: list,
					pagination: {
						current: page,
						onChange: setPage,
						total: pageTotal,
						onShowSizeChange: (current, size) => {
							setPageSize(size);
							setPage(1);
						},
						showTotal: total => `共${total}条数据`
					}
				}} />
			</section>

			{
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							values.number = tempFormValues.number;
							values.id = tempFormValues.id;
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="订单状态" name="status">
							<Select>
								{
									[
										{ status: 1, statusLabel: '待领取' },
										{ status: 2, statusLabel: '已领取' },
									].map((item, idx) => <Select.Option key={idx} value={item.status}>{item.statusLabel}</Select.Option>)
								}
							</Select>
						</Form.Item>
						<Form.Item label="备注" name="remark">
							<Input placeholder="请输入" allowClear />
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
