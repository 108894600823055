/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Form, Select, Input, DatePicker, Row, Button, Col } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './index.less';

import * as Request from '../../common/request';

const CompModel = props => {
	const { changeHandle, selectList = [], deleteHandle } = props;

	const [ lessonList, setLessonList ] = useState([]);
	const getLessonList = async () => {
		const result = await Request.get({
			url: '/admin/lesson/list'
		});
		if(!result) return;

		setLessonList(result.data.list);
	};
	useEffect(() => {
		getLessonList();
	}, [123]);

	if(!selectList || !selectList.length) return null;

	const infoById = id => {
		const target = lessonList.filter(res => res.id == id);
		return target && target[0] || {};
	};

	return (
		<div className="lesson-list-wrap">
			{
				selectList.map((item, idx) => {
					return (
						<div className="lesson-list-item" key={idx}>
							<label>第{idx + 1}天</label>
							<Select onChange={(value) => {
								const info = infoById(value);
								changeHandle && typeof changeHandle == 'function' && changeHandle(info, idx);
							}} value={item.lessonId}>
								{
									lessonList && lessonList.length > 0 && lessonList.map((litem, lidx) => {
										return <Select.Option value={litem.id} key={lidx}>{litem.title}</Select.Option>
									})
								}
							</Select>
							<div className="lesson-item-del" onClick={() => {
								deleteHandle && typeof deleteHandle == 'function' && deleteHandle(idx);
							}}>删除</div>
						</div>
					)
				})
			}
		</div>
	)
};

export default CompModel;