/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

import IMG_DEFAULT from '../../common/images/policeman-default.png';
import {isSuperAdmin} from "../../common/utils";

const PageModel = props => {
	const [form] = Form.useForm();
	const type = 2;
	const [ page, setPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState(10);
	const [ pageTotal, setPageTotal ] = useState(0);
	const [ list, setList ] = useState([]);
	const [queryData, setQueryData] = useState({});
	const getList = async (data = {}) => {
		queryData.type = type;
		queryData.page = page - 1;
		queryData.pageSize = pageSize;
		console.log("data",data)
		console.log("queryData",queryData)

		if(queryData.fulfillTimeRange){
			const _changetime = queryData.fulfillTimeRange;
			const start = _changetime[0] && moment(_changetime[0]).format('YYYY-MM-DD');
			const end = _changetime[1] && moment(_changetime[1]).format('YYYY-MM-DD');
			queryData.fillTimeRange = `${start},${end}`;
		}
		const result = await Request.get({
			url: '/admin/user/list',
			data:{
				...queryData,
				...data
			}
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList([...result.data.list]);
		setPageTotal(result.data.count);
	};
	useEffect(() => {
		getList();
	}, [ page, queryData ]);
	useEffect(() => {
		setPage(1);
	}, [ pageSize ]);

	const [ userInfo, setUserInfo ] = useState({});

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/user/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	console.log(userInfo, 'userInfo1');
	return (
		<Page {...{
			title: '用户信息',
			breadcrumbs: [ { text: '首页' }, { text: '用户信息' }, { text: ({ 1: '普通用户', 2: '处置人员' })[type] } ],
			syncUserInfo: data => {
				setUserInfo(data)
			},
			...props
		}}>
			<section className="user-container">
				<FilterForm {...{
					fields: [
						{ type: 'select', label: '选择部门/村居', name: 's-department', placeholder: '请选择', options: CONST.departmentMap.map(item => ({ id: item, label: item })) },
						// { type: 'select', label: '是否平台工作人员', name: 's-type', placeholder: '请选择', options: [{ id:2, label: '是' }, { id: 1, label: '否' }] },
						{ type: 'input', label: '姓名/昵称', name: 's-nickname', placeholder: '请输入' },
						{ type: 'range-date', label: '信息完善时间', name: 's-fulfillTimeRange', placeholder: [ '请选择', '请选择' ] },
					],
					filterHandle: data => {
						setQueryData({...data});
						setPage(1);
						// console.log('data', data);
						// getList(data);
					},
					// extra: {
					// 	btnText: '注册成为工作人员',
					// 	actionHandle: () => {
					// 		Modal.info({
					// 			title: '扫码注册成为工作人员',
					// 			content: (
					// 				<div style={{ marginTop: 10 }}>
					// 					<img src={IMG_REGISTER_QRCCODE} style={{ width: '75%' }} />
					// 				</div>
					// 			),
					// 			onOk() {},
					// 		});
					// 	}
					// },
					exportHandle: Utils.isSuperAdmin(userInfo?.mobile) && (async data => {
						data.type = type;
						const result = await Request.get({
							url: '/admin/user/list/export',
							data
						});
						if(!result) return;
						window.open(result.data.path);
					}) || null
				}} />
				<span style={{ display: 'block', marginBottom: 10, fontWeight: '800' }}>用户数总计：{pageTotal}</span>
				<Table {...{
					columns: [
						{ title: '姓名/昵称', dataIndex: 'nickname', key: 'nickname' },
						{ title: '所在部门/村居', dataIndex: 'department', key: 'department' },
						{
							title: '性别', dataIndex: 'sex', key: 'sex',
							render: (text, record, index) => <span>{({ 1: '男', 2: '女', 0: '不详' })[record.sex]}</span>
						},
						// { title: '生日', dataIndex: 'birthday', key: 'birthday' },
						// { title: '签名', dataIndex: 'sign', key: 'sign' },
						{
							title: '头像', dataIndex: 'cover', key: 'cover',
							render: (text, record, index) => <img src={record.cover && (record.cover.startsWith('http') ? record.cover : CONST.imgPrefix + record.cover) || IMG_DEFAULT} style={{ height: 32 }} />
						},
						{ title: '手机号码', dataIndex: 'mobile', key: 'mobile' },
						{
							title: '注册时间', dataIndex: 'createdAt', key: 'createdAt',
							render: (text, record, index) => <span>{record.createdAt && moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
						},
						{
							title: '信息完善时间', dataIndex: 'fulFillTime', key: 'fulFillTime',
							render: (text, record, index) => <span>{record.fulFillTime && moment(record.fulFillTime).format('YYYY-MM-DD HH:mm:ss')}</span>
						},
						// {
						// 	title: '是否平台工作人员', dataIndex: 'type', key: 'type',
						// 	render: (text, record, index) => <span>{record.type == 2 ? '是' : '否'}</span>
						// },
						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 180, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
								return <>
									{
										Utils.isSuperAdmin(userInfo?.mobile) && <Button danger type="text" size="small" style={_style} onClick={() => {
											Modal.confirm({
												title: '删除的数据将无法找回，请慎重操作！',
												icon: <WarningOutlined style={{ color: '#E45357' }} />,
												// content: 'Bla bla ...',
												okText: '确认删除',
												cancelText: '取消',
												onOk: () => {
													deleteHandle(record.id, () => {
														getList();
													})
												},
												onCancel: () => {
													// console.log('Cancel');
												},
											});
										}}>删除</Button>
									}
								</>
							}
						},
					],
					dataSource: list,
					pagination: {
						hideOnSinglePage: true,
						current: page,
						onChange: setPage,
						pageSize:pageSize,
						total: pageTotal,
						onShowSizeChange: (current, size) => {
							setPageSize(size);
						}
					}
				}} />
			</section>

		</Page>
	);
};

export default PageModel;
