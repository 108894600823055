/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [ page, setPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState(10);
	const [ pageTotal, setPageTotal ] = useState(0);
	const [ list, setList ] = useState([]);
	const getList = async (data = {}) => {
		data.page = page - 1;
		data.pageSize = pageSize;

		if(data.changetime){
			const _changetime = data.changetime;
			const start = _changetime[0] && moment(_changetime[0]).format('YYYY-MM-DD');
			const end = _changetime[1] && moment(_changetime[1]).format('YYYY-MM-DD');
			data.changetime = `${start},${end}`;
		}

		const result = await Request.get({
			url: '/admin/score/list',
			data
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList(result.data.list);
		setPageTotal(result.data.count);
	};
	useEffect(() => {
		getList();
	}, [ page ]);
	useEffect(() => {
		setPage(1);
	}, [ pageSize ]);

	const [ modalFlag, setModalFlag ] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);
	const [ fileList, setFileList ] = useState([]);
	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setModalFlag(false);
	};
	const submitHandle = async (data, callback) => {
		let url = '/admin/score/add';
		const result = await Request.post({
			url,
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);
		setFileList([]);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/score/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	const [ userList, setUserList ] = useState([]);
	const getUserList = async () => {
		const result = await Request.get({
			url: '/admin/user/list'
		});
		if(!result) return;
		setUserList(result.data.list);
	};
	useEffect(() => {
		getUserList();
	}, [123]);

	const [ userInfo, setUserInfo ] = useState({});

	return (
		<Page {...{
			title: '积分管理',
			breadcrumbs: [ { text: '首页' }, { text: '积分管理' } ],
			syncUserInfo: data => {
				setUserInfo(data)
			},
			...props
		}}>
			<section className="score-container">
				<FilterForm {...{
					fields: [
						{ type: 'input', label: '用户名称', name: 's-nickname', placeholder: '请输入' },
						{ type: 'select', label: '所在村居', name: 's-department', placeholder: '请选择', options: CONST.departmentMap.map(item => ({ id: item, label: item })) },
						{ type: 'range-date', label: '选择积分变更时间', name: 's-changetime', placeholder: [ '请输入起始时间', '请输入结束时间' ], style: { width: 400 } },
					],
					filterHandle: data => {
						getList(data);
					},
					exportHandle: async data => {
						const result = await Request.get({
							url: '/admin/score/list/export',
							data
						});
						if(!result) return;
						window.open(result.data.path);
					},
					// extra: {
					// 	btnText: '新增积分',
					// 	actionHandle: () => {
					// 		form.resetFields();
					// 		setTempFormValues(null);
					// 		setModalFlag(true);
					// 	}
					// }
				}} />

				<Table {...{
					columns: [
						{
							title: '用户', dataIndex: 'nickname', key: 'nickname',
							render: (text, record, index) => {
								let str = record.userInfo && record.userInfo.nickname;
								if(record.userInfo && record.userInfo.department) {
									str += `（${record.userInfo.department}）`;
								}
								return <span>{str}</span>
							}
						},
						// { title: '积分变更描述', dataIndex: 'desc', key: 'desc' },
						{
							title: '积分变更值', dataIndex: 'value', key: 'value',
							render: (text, record, index) => <span style={{ color: ({ 1: '#ff3d3d', 2: '#01b202' })[record.type] }}>{({ 1: '+', 2: '-' })[record.type]}{record.value}积分</span>
						},
						{
							title: '变更时间', dataIndex: 'createdAt', key: 'createdAt',
							render: (text, record, index) => <span>{record.createdAt && moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</span>
						},
						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 180, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10,	fontSize: 12 };
								return <>
									{
										Utils.isSuperAdmin(userInfo?.mobile) && <Button danger type="text" size="small" style={_style} onClick={() => {
											Modal.confirm({
												title: '删除的数据将无法找回，请慎重操作！',
												icon: <WarningOutlined style={{ color: '#E45357' }} />,
												// content: 'Bla bla ...',
												okText: '确认删除',
												cancelText: '取消',
												onOk: () => {
													deleteHandle(record.id, () => {
														getList();
													})
												},
												onCancel: () => {
													// console.log('Cancel');
												},
											});
										}}>删除</Button>
									}
								</>
							}
						},
					],
					dataSource: list,
					pagination: {
						current: page,
						onChange: setPage,
						total: pageTotal,
						onShowSizeChange: (current, size) => {
							setPageSize(size);
						}
					}
				}} />
			</section>

			{
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						{
							userList && userList.length > 0 && <Form.Item label="目标用户" name="userId">
								<Select placeholder="请选择">
									{
										userList.map((item, idx) => <Select.Option key={idx} value={item.id}>{item.nickname}</Select.Option>)
									}
								</Select>
							</Form.Item>
						}
						<Form.Item label="积分变更描述" name="desc">
							<Input placeholder="请输入" allowClear />
						</Form.Item>
						<Form.Item label="加减积分" name="type">
							<Select placeholder="请选择">
								{
									[
										{ status: 1, statusLabel: '加积分' },
										{ status: 2, statusLabel: '减积分' },
									].map((item, idx) => <Select.Option key={idx} value={item.status}>{item.statusLabel}</Select.Option>)
								}
							</Select>
						</Form.Item>
						<Form.Item label="积分值" name="value">
							<Input placeholder="请输入" allowClear />
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
