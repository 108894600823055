import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

// import PageDetail from './pages/detail';

import PageAdmin from './pages/admin';
import PageRole from './pages/role';
import PagePermission from './pages/permission';
import PageLogin from './pages/login';
import PageDashboard from './pages/dashboard';
import PageProblem from './pages/problem';
import PageProblemDetail from './pages/problemDetail';
import PageProduct from './pages/product';
import PageDynamic from './pages/dynamic';
import PageOrder from './pages/order';
import PageUser from './pages/user';
import PageWorker from './pages/worker';
import PageDashboardHot from './pages/dashboardhot'
import PageDashboardList from './pages/dashboardlist'
import PageMessage from './pages/message';
import PageDetail from './pages/detail';
import PageScore from './pages/score';
import PageDepartmentScore from './pages/departmentScore';
import PageWriteOff from './pages/writeOff';

const App = () => (
	<Router>
		<div>
			<Route exact path="/login" component={PageLogin} />
			<Route exact path="/dashboard" component={PageDashboard} />
			<Route exact path="/dashboardhot" component={PageDashboardHot} />
			<Route exact path="/dashboardlist" component={PageDashboardList} />
			<Route exact path="/problem" component={PageProblem} />
			<Route exact path="/problem/:id" component={PageProblemDetail} />
			<Route exact path="/dynamic" component={PageDynamic} />
			<Route exact path="/product" component={PageProduct} />
			<Route exact path="/order" component={PageOrder} />
			<Route exact path="/user" component={PageUser} />
			<Route exact path="/worker" component={PageWorker} />
			<Route exact path="/message" component={PageMessage} />
			<Route exact path="/score" component={PageScore} />
			<Route exact path="/dscore" component={PageDepartmentScore} />
			<Route exact path="/detail/:page/:id" component={PageDetail} />
			<Route exact path="/order/write-off/:number" component={PageWriteOff} />
			<Route exact path="/sys/admin" component={PageAdmin} />
			<Route exact path="/sys/role" component={PageRole} />
			<Route exact path="/sys/permission" component={PagePermission} />
			<Route exact path="/">
				<Redirect to="/dashboard" />
			</Route>
		</div>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
